import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NumericFormat from 'react-number-format';
import ClipboardJS from 'clipboard';

import * as DASlices from '../../da/slice';

import SnShare from '../share/share'

import { idDev, isProd } from '../../utils/ui-res';
import { actImagePath } from '../../utils/ui-res'
import { numberFormat, timeFormat, dateFormat, getPara, setPara } from '../../utils/utils'
import { showPriceData, subs } from '../../utils/utils';
import { logTrace } from '../../utils/log';

import { GoBack, CreateIcon, ClockCounter } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2, DATexts } from '../../const/';
import AppGame from '../../const/app.json';

import './activity.scss';

import Modal from 'react-modal';
Modal.setAppElement('#root');


function pctNumber(pt){
    pt = pt.replace('%', '');
    return (pt - 0)/100;
}

export function getActiveRewardPool(gameInfo, rewardPoolConfig, seasonId){
    gameInfo = gameInfo[seasonId] || {};
    let totalFinalPoolValue = gameInfo.totalFinalPoolValue - 0;
    let totalNextGamePoolValue = (gameInfo.totalNextGamePoolValue || 0) - 0;
    return totalNextGamePoolValue;

    let activeRewardPool = totalFinalPoolValue * pctNumber(rewardPoolConfig.active) / pctNumber(rewardPoolConfig.individual);
    return activeRewardPool;
}

export default function ActivityPop(props){
    const dispatch = useDispatch(); 
    
    let { rewardPoolConfig, gameStart, lands, gameInfo, activeTaskData, shareResults } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;
    // console.log(111111,  gameInfo)

    const { totalPoint = 0, buyLandCoefficient = 1, shareCoefficient = 1, invitationCoefficient = 1, playerData = {} } = activeTaskData;
    let reward = 0;
    let activeRewardPool = getActiveRewardPool(gameInfo, rewardPoolConfig, seasonId);
    if(totalPoint > 0){
        reward =  activeRewardPool * playerData.point/totalPoint;        
    }

    const [activePop, setActivePop] = useState(false);
    const [sharePop, setSharePop] = useState(false);
    const [copied1, setCopied1] = useState(false);

    const [waitingPop, setWaitingPop] = useState(false);
    const [notifyPop, setNotifyPop] = useState(false);

    function getMyLandCount(lands){
        let count = 0;
        for(var prop in lands){
            let land = lands[prop];
            if(land.owner && land.owner.toLowerCase() === address.toLowerCase()){
                count += 1;
            }
        }
        return count;
    }

    useEffect(()=>{
        if(!activePop){
            return;
        }
        dispatch(DASlices.getActiveTask({
            seasonId,
            address
        }));

        new ClipboardJS('.da-copy');
    }, [activePop]); 

    let daLink = isProd ? DATexts['daLink'] : DATexts['daLinkTest'];
    let AppLink = setPara(daLink, 'inviter', address);
    let inviter = getPara(window.location.href, 'inviter');
    useEffect(()=>{
        if(!inviter || inviter === address){
            return;
        }
        dispatch(DASlices.reportInviter({
            seasonId,
            address,
            inviter
        }));
    }, [inviter]); 


    const [taskInfoPop, setTaskInfoPop] = useState(false);

    return(<>
        <div className="App-preview-map-btn App-preview-map-task"
            _ga_event="auction.active.open"
            onClick={()=>setActivePop(true)}>
            <span className="text">Quest</span>
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    height: '600px',
                    width: '780px',
                    margin: '-280px 0 0 -390px'
                }
            }}
            className = { 'App-game-modal-activity' }
            isOpen={ activePop }
            _ga_event="auction.active.close"
            onAfterOpen={  ()=>{ return; }  }
            onRequestClose={ ()=>{ setActivePop(false) } }
            contentLabel="Activities Modal">
            <div className="App-game-modal-activity-header">
                <div _ga_event="auction.active.close" 
                    onClick={(e)=> { setActivePop(false) }} 
                    style={{ position: 'absolute', top: '0px', left: '20px', zIndex: 999 }}>
                    <GoBack size="60px" />
                </div>
            </div>
            <div className="App-game-modal-activity-title">
                <div className="activity-title">
                    <span style={{ position: 'absolute', left: '-20px', top: '-8px' }}>
                        <CreateIcon type="score" size="50" />
                    </span>
                    Activity
                </div>

                <span style={{ top: '14px', left: '-30px', position: 'relative' }}
                    onClick={ ()=>{ setTaskInfoPop(true) } }>
                    <CreateIcon type="info-tips" size="28" />
                </span> 

                <div className="App-game-modal-activity-points">
                    <label>Active Reward</label>
                    <span>
                        <CreateIcon type="zeta" />{ ' ' } 
                        <NumericFormat value={ showPriceData(activeRewardPool, 18, 4) } displayType="text" thousandSeparator="," />
                    </span>
                </div>
                <div className="App-game-modal-activity-points">
                    <label>Active Points</label>
                    <span>
                        <CreateIcon type="score" /> { playerData.point }
                    </span>
                </div>
                <div className="App-game-modal-activity-points">
                    <label>Expected Dividends</label>
                    <span>
                        <CreateIcon type="zeta" />{ ' ' } 
                        <NumericFormat value={ showPriceData(reward, 18, 4) } displayType="text" thousandSeparator="," />
                    </span>
                </div>
            </div>
            <div className="App-game-modal-activity-list">
                <div className="App-game-modal-activity-item App-game-modal-activity-item-share">
                    <div className="App-game-modal-activity-desc">
                        <h2>{ DATexts['dialog.share.share.title'] }</h2>
                        <pre>{ subs(DATexts['dialog.share.share.content'], { count: shareCoefficient }) }</pre>
                        <div className="reward">Reward: <CreateIcon type="score" /> ×{ playerData.shareCnt * shareCoefficient }</div>
                    </div>
                    <div className="App-game-modal-activity-btn">
                        <h2>{ playerData.shareCnt }/1</h2>
                        <span 
                            _ga_event="auction.active.share" 
                            onClick={ ()=>{
                                setSharePop(true)
                            } }
                            style={{ margin: '0 0 0 50px', zoom: '1' }}
                            className="App-general-btn App-general-btn-green">
                            { DATexts['dialog.share.share.btn'] }
                        </span>
                    </div>
                </div>
                <div className="App-game-modal-activity-item App-game-modal-activity-item-invitation">
                    <div className="App-game-modal-activity-desc">
                        <h2>{ DATexts['dialog.share.invite.title'] }</h2>
                        <pre>{ subs(DATexts['dialog.share.invite.content'], { count: invitationCoefficient }) }</pre>
                        <div className="reward">Reward: <CreateIcon type="score" /> ×{ playerData.invitationCnt * invitationCoefficient }</div>
                    </div>
                    <div className="App-game-modal-activity-btn">
                        <h2>{ playerData.invitationCnt }/3</h2>
                        <span 
                            _ga_event="auction.active.invite"
                            data-clipboard-text={ AppLink }
                            onClick={ (event)=>{
                                setCopied1(true);
                                setTimeout(function(){
                                    setCopied1(false);   
                                }, 1000);
                            }}
                            style={{ margin: '0 0 0 50px', zoom: '1' }}
                            className="da-copy App-general-btn App-general-btn-green">
                            { DATexts['dialog.share.invite.btn'] }
                        </span>
                        {copied1 && <span className="da-copied">Copied!</span>}
                    </div>
                </div>
                <div className="App-game-modal-activity-item App-game-modal-activity-item-plots">
                    <div className="App-game-modal-activity-desc">
                        <h2>{ DATexts['dialog.share.plot.title'] }</h2>
                        <pre>{ subs(DATexts['dialog.share.plot.content'], { count: buyLandCoefficient }) }</pre>
                        <div className="reward">Reward: <CreateIcon type="score" /> ×{ playerData.buyLandCnt * buyLandCoefficient }</div>
                    </div>
                    <div className="App-game-modal-activity-btn">
                        <h2>{ playerData.buyLandCnt }/3</h2>
                        <span 
                            _ga_event="auction.active.bidPlot" 
                            onClick={(e)=> { setActivePop(false) }} 
                            style={{ margin: '0 0 0 50px', zoom: '1' }}
                            className="App-general-btn App-general-btn-green">
                            { DATexts['dialog.share.plot.btn'] }
                        </span>
                    </div>
                </div>
            </div>

            {sharePop && <SnShare 
                content={<div className="App-sns-share App-game-modal-activity-item">
                        <div className="intro"
                            style={{ lineHeight: '1.4', padding: '0px 20px 20px 120px' }}>
                            { subs(DATexts['dialog.share.share.content'], { count: shareCoefficient }) }
                        </div>
                    </div>} 
                params = { AppGame }
                appLink = { AppLink }
                seasonId = { seasonId }
                address = { address }
                facebook={ subs(DATexts['dialog.share.share.content'], { count: shareCoefficient }) + ' %{appLink}' } 
                twitter={ subs(DATexts['dialog.share.share.content'], { count: shareCoefficient }) + ' %{appLink}' } 
                onClose={ ()=>{
                    setSharePop(false)
                }}
                onShare={ (data)=>{
                    // setSharePop(false)
                    // dispatch(DASlices.completeShareTask({
                    //     platform: data.platform,
                    //     address,
                    //     seasonId
                    // }))
                }
            } />}
        </Modal>

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '190px',
                    width: '500px',
                    margin: '-180px 0 0 -250px'
                }
            }}
            className = { 'App-game-modal-sign-overview App-da-modal' }
            isOpen={ taskInfoPop }
            onRequestClose = { () =>{ setTaskInfoPop(false) } }
            contentLabel="Camp Overview">
            <div className="App-game-modal-sign-overview-content">    
                <div _ga_event={ "auction.reward.pool.close" } 
                    style={{ position: 'absolute', margin: '-20px 0 0 20px' }}
                    onClick={ ()=>{ setTaskInfoPop(false) } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-content1" style={{ padding: '40px' }}>
                    <br />
                    Users earn points by completing tasks during the season, and receive active rewards based on the points after the season ends. The active rewards account for 2% of the total reward pool.
                </div>
            </div>
        </Modal>
    </>);
}