import { createSlice, createAsyncThunk, createEntityAdapter } from '@reduxjs/toolkit'

import { apiHost, wsHost } from '../const';
import * as API from './api';
import * as DAContract from './contract';
import * as EthFuns from '../wallets/ethereum';
import { logTrace } from '../utils/log'
import { NFTConfig } from '../const/';

export const getDASeasonList = createAsyncThunk("daSeason/list", async () => {
    const response = await new Promise((resolve, reject) => {
        API.getDASeasonList(function(res){
            resolve(res);
        });
    });
    return response;
});

export const getHistorySeasons = createAsyncThunk("getHistorySeasons/list", async () => {
    let res = await API.getHistorySeasons();
    const response = await new Promise((resolve, reject) => {
        resolve(res);
    });
    return response;
});

export const refreshSeasonInfo = createAsyncThunk("daSeason/refresh", async (data) => {
    let seasonInfo = await API.refreshSeasonInfo(data);
    const response = await new Promise((resolve, reject) => {
        resolve(seasonInfo);
    });
    return response;
});

export const getLands = createAsyncThunk("getLands/list", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getLands(data, function(res){
            resolve({data, res});
        });
    });
    return response;
});

export const getLandsDetail = createAsyncThunk("getLandsDetail/list", async (seasonInfo) => {
    const response = await new Promise((resolve, reject) => {
        API.getLandsDetail(seasonInfo, function(lands){
            resolve(lands);
        });
    });
    return response;
});

export const getDARewords = createAsyncThunk("getDARewords/list", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getDARewords(data, function(res){
            resolve({data, res});
        });
    });
    return response;
});

export const getSeasonReword = createAsyncThunk("getSeasonReword/list", async (data) => {
    let res = await API.getSeasonReword(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const getDAEvents = createAsyncThunk("getDAEvents/list", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getDAEvents(data, function(res){
            resolve({data, res});
        });
    });
    return response;
});

export const getUnionInfo = createAsyncThunk("getUnionInfo/get", async (data) => {
    const response = await new Promise((resolve, reject) => {
        DAContract.getUnionInfo(data, function(res){
            resolve({data, res});
        });
    });
    return response;
});

export const getLandInfo = createAsyncThunk("getLandInfo/get", async (data) => {
    let res = await API.getLandInfo(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const getLandKLineData = createAsyncThunk("getLandKLineData/get", async (data) => {
    let res = await API.getLandKLineData(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const getCapitalInfo = createAsyncThunk("getCapitalInfo/get", async (data) => {
    // await EthFuns.connect();
    const response = await new Promise((resolve, reject) => {
        DAContract.getLandInfo(data, function(res){
            resolve({data, res});
        });
    });
    return response;
});

export const getGameInfo = createAsyncThunk("getGameInfo/get", async (data) => {
    const response = await new Promise((resolve, reject) => {
        DAContract.getGameInfo(data, function(res){
            resolve({data, res});
        });
    });
    return response;
});

export const getShareInfo = createAsyncThunk("getShareInfo/get", async (data) => {
    const response = await new Promise((resolve, reject) => {
        DAContract.getShareInfo(data, function(res){
            resolve({data, res});
        });
    });
    return response;
});

export const getClaimedReword = createAsyncThunk("getClaimedReword/get", async (data) => {
    const response = await new Promise((resolve, reject) => {
        API.getClaimedReword(data, function(res){
            resolve(res);
        });
    });
    return response;
});

export const getAllRewards = createAsyncThunk("getAllRewards/get", async (data) => {
    let { seasonHis, userInfo } = data;
    let res = await DAContract.getAllRewards(seasonHis, userInfo);
    const response = await new Promise((resolve, reject) => {
        resolve(res);
    });
    return response;
});

export const createSeason = createAsyncThunk("createSeason/get", async (data) => {
    let res = await DAContract.createSeason(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const joinUnion = createAsyncThunk("joinUnion/get", async (data) => {
    let res = await DAContract.joinUnion(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const buyLand = createAsyncThunk("buyLand/get", async (data) => {
    let res = await DAContract.buyLand(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const getAllBuyLandEvents = createAsyncThunk("getAllBuyLandEvents/get", async (data) => {
    let res = await API.getAllBuyLandEvents(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const withdrawDividends = createAsyncThunk("withdrawDividends/get", async (data) => {
    let res = await DAContract.withdrawDividends(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const withdrawFinal = createAsyncThunk("withdrawFinal/get", async (data) => {
    let res = await DAContract.withdrawFinal(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const withdrawTaskReward = createAsyncThunk("withdrawTaskReward/get", async (data) => {
    let res = await DAContract.withdrawTaskReward(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const getActiveTask = createAsyncThunk("getActiveTask/get", async (data) => {
    let res = await API.getActiveTask(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const completeShareTask = createAsyncThunk("completeShareTask/get", async (data) => {
    let res = await API.completeShareTask(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const reportInviter = createAsyncThunk("reportInviter/get", async (data) => {
    let res = await API.reportInviter(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const getClaimedRecords = createAsyncThunk("getClaimedRecords/get", async (data) => {
    let res = await API.getClaimedRecords(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const getActiveRewards = createAsyncThunk("getActiveRewards/get", async (data) => {
    let res = await API.getActiveRewards(data);
    const response = await new Promise((resolve, reject) => {
        resolve({data, res});
    });
    return response;
});

export const _adapter = createEntityAdapter();

const initialState = _adapter.getInitialState({
    rewardPoolConfig: {
        dividend: '35%',
        conquest: '10%',
        individual: '45%',
        developer: '5%',
        active: '5%'
    },
    daSeasons: [],
    seasonHis: [],
    seasonAll: [],
    gameStart: {
        seasonInfo: {},
        unionId: 0,
        address: '',
        isStart: false
    },
    lands: {},
    landsDetail: {},
    events: {
        isRecent: false,
        list: [],
        recents: []
    },
    seasonReword: {},
    seasonIncomes: {},
    buyRecords: {},
    buyLandEvents: {},
    rewords: [],
    unionInfo: {},
    landsInfo: {},
    kLineData: {},
    capitalInfo: {},
    gameInfo: {},
    claimedReword: {},
    allRewards: { back: false },
    shareInfo: {},
    opResults: {
        type: '',
        biz: '',
        back: false,
        res: {}
    },
    shareResults: {
        type: '',
        back: false,
        res: {}
    },
    rewards: {
        mine: 0,
        final: 0
    },
    activeRewards: {},
    activeTaskData: {},
    claimedRecords: {}
});

const DASlice = createSlice({
    name: 'dauction',
    initialState,
    reducers: {
        setGameStart: function(state, action){
            let { seasonInfo = {}, unionId, address, isStart = false } = action.payload || {};
            state.gameStart = { seasonInfo, unionId, address, isStart };
        },
        resetLandInfo: function(state, action){
            let { blockId } = action.payload;
            state.landsInfo[blockId] = {};
        },
        resetResult: function(state, action){
            state.opResults = {
                type: '',
                back: false,
                res: {}
            };
        },
        resetBuyRecords: function(state, action){
            let { seasonId } = action.payload || {};
            state.buyLandEvents[seasonId] = {};
        }
    },
    extraReducers: builder => {
        builder.addCase(getDASeasonList.fulfilled, (state, action) => {
            let res = action.payload;
            let { seasons = [], seasonAll = [] } = res;
            state.daSeasons = seasons;
            state.seasonAll = seasonAll;
        });
        builder.addCase(getHistorySeasons.fulfilled, (state, action) => {
            let res = action.payload;
            state.seasonHis = res;
        });
        builder.addCase(refreshSeasonInfo.fulfilled, (state, action) => {
            let seasonInfo = action.payload;
            if(seasonInfo.sid){
                state.gameStart.seasonInfo = seasonInfo;                
            }
        });
        builder.addCase(getLands.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.lands = res || {};
        });
        builder.addCase(getLandsDetail.fulfilled, (state, action) => {
            let landsDetail = action.payload;
            state.landsDetail = landsDetail || {};
        });
        builder.addCase(getDAEvents.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.events = res || {};
            state.buyRecords[data.seasonId] = res.list || [];
        });
        builder.addCase(getDARewords.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.rewords = res || [];
            state.seasonIncomes[data.seasonId] = res || [];
        });
        builder.addCase(getSeasonReword.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.seasonReword[data.seasonId] = res || [];
        });
        builder.addCase(getUnionInfo.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.unionInfo[data.seasonId] = res || {};
        });
        builder.addCase(getLandInfo.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            let blockId = data.blockId;
            // let r = Math.ceil(Math.random()*987)%9 + 1;
            // res.nextLandPrice = r + '1876543210000000000';
            state.landsInfo[blockId] = res || {};
        });
        builder.addCase(getLandKLineData.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            let { blockId } = data;
            state.kLineData[blockId] = res || [];
        });
        builder.addCase(getCapitalInfo.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.capitalInfo = res;
        });
        builder.addCase(getGameInfo.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.gameInfo[data.seasonId] = res || {};
        });
        builder.addCase(getShareInfo.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.shareInfo[data.seasonId] = res || {};
        });
        builder.addCase(getClaimedReword.fulfilled, (state, action) => {
            let res = action.payload;
            state.claimedReword = res;
        });
        builder.addCase(getAllRewards.fulfilled, (state, action) => {
            let res = action.payload;
            state.allRewards = res;
        });
        builder.addCase(createSeason.fulfilled, (state, action) => {
            let { res } = action.payload;
            state.opResults = {
                type: 'createSeason',
                back: true,
                result: res.result,
                res: res
            };
        });
        builder.addCase(joinUnion.fulfilled, (state, action) => {
            let { res } = action.payload;
            state.opResults = {
                type: 'joinUnion',
                back: true,
                result: res.result,
                res: res
            };
        });
        builder.addCase(buyLand.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.opResults = {
                type: 'buyLand',
                back: true,
                xyIndex: data.p,
                result: res.result,
                res: res
            };
        });
        builder.addCase(getAllBuyLandEvents.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            let { seasonId, pageNumber, pageSize } = data;
            let { list, total } = res;
            let cache = state.buyLandEvents[seasonId] || {};
            let records = cache['records'] || [];
            state.buyLandEvents[seasonId] = {
                isBack: true,
                total,
                pageSize,
                pageNumber,
                records: records.concat(list)
            };
        });
        builder.addCase(withdrawDividends.fulfilled, (state, action) => {
            let { res, data } = action.payload;
            state.opResults = {
                type: 'withdrawDividends',
                biz: 'withdraw',
                back: true,
                result: res.result,
                res: res,
                data
            };
        });
        builder.addCase(withdrawFinal.fulfilled, (state, action) => {
            let { res, data } = action.payload;
            state.opResults = {
                type: 'withdrawFinal',
                biz: 'withdraw',
                back: true,
                result: res.result,
                res: res,
                data
            };
        });
        builder.addCase(withdrawTaskReward.fulfilled, (state, action) => {
            let { res, data } = action.payload;
            state.opResults = {
                type: 'withdrawTaskReward',
                biz: 'withdraw',
                back: true,
                result: res.result,
                res: res,
                data
            };
        });
        builder.addCase(getActiveTask.fulfilled, (state, action) => {
            let { data, res } = action.payload;
            state.activeTaskData = res;
        });
        builder.addCase(completeShareTask.fulfilled, (state, action) => {
            let { res, data } = action.payload;
            state.shareResults = {
                type: 'completeShareTask',
                back: true,
                result: res.result,
                res: res,
                data
            };
        });
        builder.addCase(reportInviter.fulfilled, (state, action) => {
            let { res, data } = action.payload;
            state.shareResults = {
                type: 'reportInviter',
                back: true,
                result: res.result,
                res: res,
                data
            };
        });
        builder.addCase(getClaimedRecords.fulfilled, (state, action) => {
            let { res, data } = action.payload;
            state.claimedRecords = res;
        });
        builder.addCase(getActiveRewards.fulfilled, (state, action) => {
            let { res, data } = action.payload;
            state.activeRewards[data.seasonId] = res;
        });
    }
});

export const { 
    resetResult, setGameStart, resetBuyRecords, resetLandInfo
} = DASlice.actions

export default DASlice.reducer;