import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import NumericFormat from 'react-number-format';
import { GoBack, ToastTip, CreateIcon, ClockCounter } from '../../components'
import Modal from 'react-modal';

import * as DASlices from '../../da/slice';

import BlockBasic from './basic';
import KLineMap from './kline';
import PurchaseHistory from './purchase-history';
import MiningRule from './rule';

import { getMapId, getMapConfig, getMapGDS } from '../../maps/map-res'
import { Texts, DATexts } from '../../const';
import { logTrace } from '../../utils/log'
import { getWalletSignErrMessage, showPriceData } from '../../utils/utils'

import './block.scss';

var occupiedsTemp = {};
var mapId = 7;
var { mapGDS, distancesConfig } = getMapGDS(mapId);
export default function BlockDetail(props){
    const dispatch = useDispatch(); 
	let { tileInfo, unlockedRing } = props;
    const tileData = tileInfo.data.data || {};
    
    const blockId = tileData.xyIndex || '';
    const xyIndex = blockId.split('^');
    const x_id = xyIndex[0]/1;
    const y_id = xyIndex[1]/1;

    const blockGDS = mapGDS[tileData.xyIndex] || {};
    const keys = {
        name: 'dialog.map.block_' + blockGDS.type + '_' + (blockGDS.parameter || 0) + '.name',
    }

    const { gameStart, landsInfo = {}, lands } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;
    const landInfo = landsInfo[blockId] || {};
    // console.log(landInfo, seasonInfo);
   
    const mapConfig = getMapConfig(mapId);
    const myCamp = mapConfig.inits[unionId];
    let occupieds = {};
        occupieds[myCamp.x_id + '^' + myCamp.y_id] = { unionId }
    
    function isInitBlock(){
        let inits = mapConfig.inits;
        let isInit = false;
        for(var unionId in inits){
            let blockInfo = inits[unionId];
            if(blockInfo.x_id === x_id && blockInfo.y_id === y_id){
                isInit = true;
            }
        }
        return isInit;
    }

    function getPriceAdjustTime(){
        let { lastUpdatedAt, nextLandPrice, currLandPrice, protectTimeTs = 0 } = landInfo;
        let { unlockedRingTs } = seasonInfo;
        let distance = distancesConfig[blockId];
        lastUpdatedAt = lastUpdatedAt/1 || unlockedRingTs[distance] || seasonInfo.startAt/1;
        // console.log(unlockedRingTs, distance, unlockedRingTs[distance], lastUpdatedAt);
        let unit = 300;
        let now = new Date().getTime()/1000;
        let diff = unit - (now - lastUpdatedAt - protectTimeTs)%unit;
        return diff;
        // return diff > 0 ? diff%unit : unit + diff%unit;
    }

    function getProtectRemainTime(){
        let { lastUpdatedAt = 0, protectTimeTs = 0 } = landInfo;
        lastUpdatedAt = lastUpdatedAt - 0;
        protectTimeTs = protectTimeTs - 0;
        let now = new Date().getTime()/1000;
        if(protectTimeTs === 0 || lastUpdatedAt + protectTimeTs <= now){
            return 0;
        }
        return (lastUpdatedAt + protectTimeTs - now);
    }

    const [ colckTimer, setColckTimer ] = useState(true);
    useEffect(()=>{
        setColckTimer(false);
        setTimeout(function(){
            setColckTimer(true);
        },0);
    }, [landInfo.nextLandPrice]);

    const [ protectEnd, setProtectEnd ] = useState(false);
    const [ noNeighbor, setNoNeighbor ] = useState({ pop: false });
    function startBid(){
        let info = {
            x_id,
            y_id,
            myUnionId: unionId,
            unlockedRing
        };
        let neighborResult = isNeighborTile(blockGDS, {}, lands, info);
        if(!neighborResult.isNeighbor){
            setNoNeighbor({
                pop: true,
                tip: DATexts['dialog.block_attack.rule']
            });
            return;
        }
        let neiIds = neighborResult.neiId.split('^');
        let params = {
            address,
            seasonId,
            p: {x: x_id, y: y_id},
            rp: {x: neiIds[0], y: neiIds[1]},
            value: landInfo.nextLandPrice - 0 || landInfo.currLandPrice - 0
        };
        dispatch(DASlices.buyLand(params));
    }

    const { opResults } = useSelector(function(state){
        return state.dauction;
    });

    useEffect(()=>{
        if(!opResults.back || opResults.type !== 'buyLand'){
            return;
        }
        setNoNeighbor({
            pop: true,
            tip: opResults.result ? 'buyLand successful.' : getWalletSignErrMessage(opResults.msg, 'bid')
        });
        let xyIndex = opResults.xyIndex || {};
        if(opResults.result && (xyIndex.x === x_id && xyIndex.y === y_id) && window.gamePVP){
            window.gamePVP.events.emit("tileAttackWin", {
                xyIndex: tileData.xyIndex,
                unionId: unionId
            });
            window.gamePVP.events.on("tileUpdated", function(){
                // setTimeout(function(){
                //     dispatch(resetBattleResult());
                //     props.close && props.close();
                // }, 2000);
            });
        }

        //only refresh data after buy land success.
        if(!opResults.result || opResults.type !== 'buyLand'){
            return;
        }

        dispatch(DASlices.getGameInfo({ seasonId, address }));
        dispatch(DASlices.getLands({ seasonId, address }));
        dispatch(DASlices.getDAEvents({
            seasonId
        }));
        dispatch(DASlices.refreshSeasonInfo({
            seasonId
        }));
        dispatch(DASlices.getLandKLineData({
            seasonId,
            blockId,
            x: x_id,
            y: y_id
        }));

        if(seasonInfo.unlockedRing !== 0){
            return;
        }
        dispatch(DASlices.getCapitalInfo({
            address,
            seasonId,
            p: {x: 0, y: 0}
        }));
    }, [opResults]);
    
    useEffect(()=>{
        dispatch(DASlices.getLandInfo({
            address,
            seasonId,
            x: x_id, 
            y: y_id,
            blockId,
            p: {x: x_id, y: y_id}
        }));
        dispatch(DASlices.getLandKLineData({
            seasonId,
            blockId,
            x: x_id,
            y: y_id
        }));
    }, []);

    let { kLineData } = useSelector(function(state){
        return state.dauction;
    });
    kLineData = kLineData[blockId] || [];
    const baseInfo = (kLineData[0] || {})['args'] || {};

	return (<>
        <div className = { 'App-acution-block-pop-mask' }  _ga_event="auction.map.block.close" onClick={ ()=>{
            props.close && props.close();
        }}></div>
		<div className = { 'App-acution-block-pop' }>
            <div className="App-acution-block-pop-coin-left"></div>
            <div className="App-acution-block-pop-coin-right"></div>
            {kLineData.length > 0 && <PurchaseHistory kLineData={ kLineData } />}
            <div className="App-game-modal-block-content" style={{ position: 'relative' }}>
                <div className="App-acution-block-data2" style={{ zoom: '0.92' }}>
                    <BlockBasic detail={ blockGDS } title={ (Texts[keys.name] || keys.name) + '(' + x_id + ',' + y_id + ')'} landInfo={ landInfo } />
                    
                    <div className="App-acution-block-datas">
                        <div className="App-acution-block-data-item App-acution-block-data-item1">
                            <label>Bid Price</label>
                            <span _price="nextLandPrice">
                                <NumericFormat value={ showPriceData(landInfo.nextLandPrice - 0, 18, 4) } displayType="text" thousandSeparator="," /> &nbsp;
                            </span>
                        </div>
                        <div className="App-acution-block-data-item App-acution-block-data-item2">
                            <label>Stock Count</label>
                            <span>
                                {landInfo.shares ? <NumericFormat value={ landInfo.shares } displayType="text" thousandSeparator="," /> : ''}  &nbsp;
                            </span>
                        </div>
                        <div className="App-acution-block-data-item App-acution-block-data-item2-2">
                            <label>Trunover</label>
                            <span>
                                { kLineData.length }
                            </span>
                        </div>

                        {landInfo['ratio'] - 0 <= 10 
                        ? <div className="App-acution-block-data-item App-acution-block-data-item4">
                                <label>{ DATexts['dialog.block.price.lowest1'] }</label>
                            </div>
                        : <div className="App-acution-block-data-item App-acution-block-data-item3" 
                            style={{ opacity: landInfo['ratio'] > 0 ? '1' : '0' }}>
                            <label>Pirce Adjustment</label>
                            {
                                (getProtectRemainTime() <= 0 || protectEnd) ?
                                <span><span>{distancesConfig[tileData.xyIndex] >= unlockedRing && colckTimer && <ClockCounter time={ getPriceAdjustTime() } onEnd={ ()=>{
                                    dispatch(DASlices.getLandInfo({
                                        address,
                                        seasonId,
                                        x: x_id, 
                                        y: y_id,
                                        blockId,
                                        p: {x: x_id, y: y_id}
                                    }));
                                } } />}</span></span>
                                : <span>&nbsp;</span>
                            }
                        </div>}
                    </div>

                    {!isInitBlock() && <>
                    { distancesConfig[tileData.xyIndex] < unlockedRing  
                    ? <div className="App-acution-block-btn App-acution-unlock-tips">unlock circle</div>
                    : <div className="App-acution-block-btn" style={{ width: '17%' }}>
                        {(getProtectRemainTime() > 0 && !protectEnd) ? <div className="App-acution-block-clock">
                            <label>Protect Countdown: </label><br />
                            <span><ClockCounter time={ getProtectRemainTime() }  onEnd={ ()=>{
                                setProtectEnd(true);
                                dispatch(DASlices.getLandInfo({
                                    address,
                                    seasonId,
                                    x: x_id, 
                                    y: y_id,
                                    blockId,
                                    p: {x: x_id, y: y_id}
                                }));
                            } }  /></span>
                        </div> : <div className="App-acution-block-clock"><label>&nbsp;</label> <br /><span>&nbsp;</span></div>}
                        { (blockGDS.type === 3 || (getProtectRemainTime() > 0 && !protectEnd) || (address === (landInfo['owner'] || '').toLowerCase()))
                        ?<> <span 
                            style={{ margin: '0 0 0 50px', zoom: '1' }}
                            className="App-general-btn App-general-btn-green App-btn-disable">
                            Bid
                        </span></>
                        : <><span 
                            onClick={ ()=>{
                                startBid();
                            } }
                            style={{ margin: '0 0 0 50px', zoom: '1' }}
                            className="App-general-btn App-general-btn-green">
                            Bid
                        </span></>}
                    </div>} </>}
                </div>
            </div>

            <div className="App-block-panel">
                {landInfo.nextLandPrice && <KLineMap blockData={ {seasonId, x: x_id, y: y_id } } kLineData={ kLineData } landInfo={ landInfo }  />}
            </div>


            <div className="App-block-foot">
                <MiningRule />
                {(!isInitBlock() && distancesConfig[tileData.xyIndex] < unlockedRing && (getProtectRemainTime() > 0 && !protectEnd)) 
                    && <div className="App-acution-block-clock" style={{ zoom: 1, margin: '0 0 0 40px' }}>
                    <label style={{ color: '#d9d5cb' }}>Protect Countdown: </label>
                    <span><ClockCounter time={ getProtectRemainTime() }  onEnd={ ()=>{
                        setProtectEnd(true);
                        dispatch(DASlices.getLandInfo({
                            address,
                            seasonId,
                            x: x_id, 
                            y: y_id,
                            blockId,
                            p: {x: x_id, y: y_id}
                        }));
                    } }  /></span>
                </div>}
            </div>
        </div>

        { (noNeighbor.pop) && <ToastTip 
            text={ noNeighbor.tip } 
            callback={ ()=>{ 
                setNoNeighbor({ pop:false });
                dispatch(DASlices.resetResult()); 
            }
        } />}
	</>)
}


function isNeighborTile(targetBlock, ports, occupieds, info){
    let flag = false;
    let y = info.y_id;
    let x = info.x_id;
    let myUnionId = info.myUnionId;
    let unlockedRing = info.unlockedRing;

    var neighbors = {};

    //top 1
    neighbors[x + '^' + (y + 2)] = true;

    //bottom 1
    neighbors[x + '^' +  (y - 2)] = true;

    //left 2
    neighbors[(x - 1) + '^' +  (y + 1)] = true;
    neighbors[(x - 1) + '^' +  (y - 1)] = true;

    //right 2
    neighbors[(x + 1) + '^' +   (y + 1)] = true;
    neighbors[(x + 1) + '^' +   (y - 1)] = true;

    ports = ports || {};
    let allMyTiles = {};
    let iHasHarbor = false;
    for(var prop in occupieds){
        let unionId = occupieds[prop].unionId || 0;
        if(unionId - 0 === myUnionId - 0){
            allMyTiles[prop] = true;
        }
        if(unionId - 0 === myUnionId - 0 && ports[prop]){
            iHasHarbor = true;
        }
    }

    let isTargetIsHarbor = (targetBlock.type == 2 && targetBlock.parameter == 14) || targetBlock.type == 8;
    if(isTargetIsHarbor && iHasHarbor){
        return true;
    }

    for(var prop in occupiedsTemp){
        let unionId = occupiedsTemp[prop].unionId || 0;
        if(unionId - 0 === myUnionId - 0){
            allMyTiles[prop] = true;
        }
    }

    let neiId = '';
    for(var id in neighbors){
        if(allMyTiles[id]){
            neiId = id;
            flag = true;
        }
    }
    return { isNeighbor: flag, neiId };
}