import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getSeasonConfig } from '../../services/slice-map';
import { getShortUrl } from '../../services/api';
import * as API from '../../da/api';

import { CreateIcon, GoBack, LoadingBtn } from '../../components'
import { NotifyPop } from '../../components'

import { Texts2, Chains } from '../../const/';
import { logTrace } from '../../utils/log'
import { subs } from '../../utils/utils'

import './share.scss'

import xImg from './assets/x.jpg';
import fbImg from './assets/fb.jpg';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SnShare(props) {
    let dispatch = useDispatch();

    const [sharePop, setSharePop] = useState(true);
    const [toVerify, setToVerify] = useState({});
    const [verifyResult, setVerifyResult] = useState({});
    const [shareLink, setShareLink ] = useState('');
    const [postLink, setPostLink ] = useState('');
    const [shortUrlReady, setShortUrlReady] = useState(false);
    console.log(verifyResult)

    let { params = {}, seasonId, address, facebook, twitter, content, appLink } = props;
    // console.log({ params, facebook, twitter, content });

    function fbShare(){
        let link = 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(params.appLink);
        window.open(link);
        // props.onShare && props.onShare({platform: 'facebook'});
        setToVerify({
            ready: true,
            platform: 'facebook',
            post_link_mock: 'https://www.facebook.com/xxxxxx/posts/xxxxxxxxxxxxxx',
            post_link_short: shareLink
        });
    }

    function twitterShare(){
        let text = encodeURIComponent(subs(twitter, params)) + ' @' + params.twitter;
        let link = 'https://twitter.com/intent/tweet?text=' + text;
        window.open(link);
        // props.onShare && props.onShare({platform: 'twitter'});
        setToVerify({
            ready: true,
            platform: 'x',
            post_link_mock: 'https://x.com/xxxxxx/status/xxxxxxxxxxxxxxxxx',
            post_link_short: shareLink
        });
    }

    function synContent(e){
        // console.log('synContent', e.target.value);
        let link = e.target.value || '';
        setPostLink(link);
        setVerifyResult({});
    }

    async function shareVerify(){
        setVerifyResult({
            status: 'verifying'
        });
        let params = JSON.parse(JSON.stringify(toVerify));
            params.post_link = postLink;
            params.address = address;
            params.seasonId = seasonId;
        let res = await API.shareVerify(params);
        // console.log('setVerifyResult', res);
        setVerifyResult(res);
    }

    useEffect(()=>{
        getShortUrl({
            url: appLink
        }, function(res){
            if(res.code === 0){
                params.appLink = res.short_url;
            }else{
                params.appLink = appLink;
            }
            setShareLink(params.appLink);
            setShortUrlReady(true);
        });
    }, [params.prod]);

    useEffect(()=>{
        if(sharePop){
            return;
        }
        setToVerify({});
        setShareLink('');
        setPostLink('');
    }, [sharePop]);

    return (<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '540px',
                    marginLeft: '-270px',
                    height: toVerify.ready ? '380px' : '280px',
                    marginTop: '-150px'
                }
            }}
            // className = { 'App-game-modal-share App-game-modal-gray' }
            className = { 'App-game-modal-activity' }
            isOpen={ shortUrlReady && sharePop }
            onRequestClose={ ()=>{ return;setSharePop(false); props.onClose && props.onClose() } }
            contentLabel="SNS SHARE">
            <div style={{ position: 'relative' }}>
                <div
                    style={{ margin: '-10px 0 0 -30px' }} 
                    _ga_event="auction.share.close" 
                    onClick={ ()=>{ setSharePop(false); props.onClose && props.onClose() } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ lineHeight: '2rem' }}>&nbsp;</div>

                {toVerify.ready ? <>
                    <div style={{ margin: '20px 50px 10px' }}>
                        <div className="App-post-link-shot">
                            <img src={ toVerify.platform === 'facebook' ? fbImg : xImg } />
                        </div>
                        <div className="App-game-chat-input" style={{ width: '100%' }}>
                            <input type="text" name="content" placeholder={ toVerify.post_link_mock }
                                style={{ width: '95%', fontSize: '13px' }}
                                value={ postLink }
                                onKeyUp= { (e)=>{
                                    synContent(e);
                                }}
                                onChange={ (e)=>{ synContent(e) } } />
                        </div>
                        <div 
                            className="App-tips-warning" 
                            style={{ 
                                padding: '10px 20px 1px', 
                                textAlign: 'center',
                                color: verifyResult.result ? '#fff' : 'RGBA(227, 29, 12, 1)'
                            }}>
                            {!verifyResult.back && ' '}
                            {verifyResult.back && (verifyResult.result ? 'Verify successful.' : (verifyResult.err || 'Verify fail.')) }
                        </div>
                    </div>
                    <div className="App-sns-share-btns" style={{ marginTop: '5px' }}>
                        {verifyResult.status === 'verifying' ? <><LoadingBtn size="28" /></>
                        : <span 
                            _ga_event="auction.share.twitter"
                            onClick={ ()=>{
                                shareVerify();
                            } } 
                            className="da-copy App-general-btn App-general-btn-green">
                            { 'Verify' }
                        </span>}
                    </div>
                </>: <>
                    { content }
                    <div className="App-sns-share-btns">
                        <span 
                            _ga_event="auction.share.twitter"
                            onClick={ ()=>{
                                twitterShare();
                            } } 
                            className="App-btn-twitter">
                            { Texts2['dialog.share.btn.twitter'] }
                        </span>
                        <span 
                            _ga_event="auction.share.facebook"
                            onClick={ ()=>{
                                fbShare(); 
                            } } 
                            className="App-btn-facebook">
                            { Texts2['dialog.share.btn.facebook'] }
                        </span>
                    </div>
                </>}
            </div>
        </Modal>
    </>);
}