import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import * as DASlices from '../../da/slice';

import { getOccupiedTiles, getUnionOverView } from '../../services/slice-map';
import { getCodList } from '../../services/slice-general';

import { unionImagePath } from '../../utils/ui-res'
import { colors, getMapId, getMapConfig } from '../../maps/map-res'

import SceneBase from './scene-base';

import { GoBack, Ranks } from '../../components'

import { Texts, Texts2, DATexts } from '../../const/';

import Modal from 'react-modal';
Modal.setAppElement('#root');

function getUnioidLands(lands){
    let occupiedTotals = {};
    for(var posId in lands){
        let blockInfo = lands[posId] || {};
        let unionId = blockInfo.unionId - 0;
        if(unionId > 0){
            occupiedTotals[unionId] = occupiedTotals[unionId] || 0;
            occupiedTotals[unionId] += 1;
        }
    }
    return occupiedTotals;
}

export default function MapPreview(props){
    const dispatch = useDispatch();
    const { unlockedRing } = props;

    const mapId = 7;
    const mapConfig = getMapConfig(mapId);
    const width = mapConfig.width;
    const height = mapConfig.height;

    const [isSnapshot, setIsSnapshot] = useState(false);
    const [playerStatPop, setPlayerStatPop] = useState(false);
    let gamePreview = null;

    let { gameStart, lands, unionInfo } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;

    useEffect(()=>{
        if(!address || !seasonId){
            return;
        }
        dispatch(DASlices.getUnionInfo({
            address,
            seasonId
        }));
    }, [seasonId, address]);

    function afterOpenModal(){
        const config = {
            callbacks: {
                preBoot: game => {
                },
            },
            type: Phaser.CANVAS,
            disableContextMenu: true,
            title: 'League of Thrones',
            parent: "App-phaser-preview",
            width: width/1.2,
            height: height,
            transparent: true,
            canvasStyle: 'height:100%;',
            scene: [ new SceneBase({occupieds: lands, codsAll: {}, mapConfig, unlockedRing }, true) ]
        };
        gamePreview = new Phaser.Game(config);
    }

    const [occupiedTotals, setOccupiedTotals] = useState({});
    const [unionMemberCnt, setUnionMemberCnt] = useState([]);
    useEffect(()=>{
        let totals = getUnioidLands(lands);
        setOccupiedTotals(totals);

        let _unionInfo = unionInfo[seasonId] || {};
        let unionMemberCnt = _unionInfo.unionMemberCnt || [];
        setUnionMemberCnt(unionMemberCnt);
    }, [lands, seasonId]);
    
    useEffect(()=>{
        props.onLoad && props.onLoad();
    }, [isSnapshot]);

    return (<>
        <div className="App-preview-map-btn" _show={ isSnapshot.toString() } id="App-preview-map-btn" 
            _ga_event="auction.map.preview.close"
            onClick={()=>setIsSnapshot(!isSnapshot)}>
            <span className="text">{ Texts['button.map_review.title'] }</span>
        </div>

        <Modal
            style = {{
                overlay: { backgroundColor: 'transparent' },
                content: {
                    width: '820px',
                    height: '475px',
                    margin: '-200px 0 0 -410px'
                }
            }}
            className = { 'App-game-modal-mappreview App-game-modal-gray' }
            isOpen={ isSnapshot }
            onAfterOpen = { afterOpenModal }
            onRequestClose = { () => setIsSnapshot(false) }
            contentLabel="Preview Map Modal">
            <div style={{ position: 'relative' }}>
                <div _ga_event={ "auction.map.preview.open" } onClick={ ()=>{ setIsSnapshot(false)  } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-header" style={{ padding: '10px', lineHeight: '2' }}>
                    { Texts['button.map_review.title'] }
                </div>
                <div className="App-game-modal-content2" style={{ display: 'flex',padding:'10px 0' }}>
                    <div className="App-game-preview-rule App-seaon-map-canvas-rotate" style={{ width: '440px' }}>
                        <div id="App-phaser-preview" style={{ width: '540px', height: '380px', marginLeft: '10px' }}></div>
                    </div>
                    <div className="App-game-table App-game-table-rank" style={{ clear: 'both', marginLeft:'0' }}>
                        <table style={{ margin: '-5px 15px 0',width: '90%' }}>
                            <thead><tr>
                                <td>{ Texts2['dialog.camp'] }</td>
                                <td className="right">{ Texts2['dialog.player'] }</td>
                                <td className="right">{ 'Plots' } &nbsp;</td>
                            </tr></thead>
                            <tbody>
                            { 
                                [1,2,3,4].map(
                                    (item, index) => (<tr key={index}>
                                        <td>&nbsp;&nbsp; <img alt="" src={ unionImagePath(item) } style={{ width: '20px',borderRadius: '10px' }} /></td>
                                        <td className="right">{ unionMemberCnt[index] || 0 } &nbsp;&nbsp;</td>
                                        <td className="right">{ occupiedTotals[index + 1] || 0 } &nbsp;</td>
                                    </tr>)
                                )
                            }
                            </tbody>
                        </table>
                        <div className="App-game-modal-content-desc" style={{ margin: '10px' }}>
                            { DATexts['button.map_review_rule.desc'] }
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>);
}