import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import * as DASlices from '../../da/slice';
import * as EthFuns from '../../wallets/ethereum';
import * as DAContract from '../../da/contract';

import { colors, unionImagePath, getNickName, shortString } from '../../utils/ui-res'
import { GoBack, CreateIcon } from '../../components'

import { Texts, Texts2, DATexts } from '../../const/';
import { zetaTransactionAddressLink, zetaTransactionLink } from '../../const';

import { numberFormat, dateFormat, showPriceData } from '../../utils/utils';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function MyEvents(props){
    const dispatch = useDispatch();
    let { seasonId, address } = props;
    if(window.location.href.indexOf('?test=1')> -1){
        seasonId = 'test-zeta_main-2024-08-22-04';
    }

    let currentType = 'Mine';
    const { buyLandEvents } = useSelector(function(state){
        return state.dauction;
    });
    const { records = [], total = 0, isBack = false } = buyLandEvents[seasonId] || {};

    const [pageNumber, setPageNumber] = useState(0);

    let pageSize = 50;

    useEffect(()=>{
        let params = { 
            seasonId,
            pageSize,
            pageNumber,
            address
        };
        dispatch(DASlices.getAllBuyLandEvents(params));
    }, [pageNumber]);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '370px',
                width: '600px',
                margin: '-200px 0 0 -300px'
            }
        }}
        className = { 'App-game-modal-sign-overview App-da-modal' }
        isOpen={ true }
        onRequestClose = { () =>{ props.close(); } }
        contentLabel="Camp Overview">
        <div className="App-game-modal-sign-overview-content">
            <div
                style={{ position: 'absolute', margin: '-20px 0 0 20px' }} 
                _ga_event={ "auction.events.mine.close" } 
                onClick={ ()=>{ props.close() } }>
                <GoBack size="60px" />
            </div>

            <div className="App-game-modal-content1" style={{ margin: '0', padding: '1px', height: '350px', }}>
            <div style={{ margin: '15px', height: '320px', overflow: 'auto' }}>
                {records.map((item, index) => {
                    let args = item.args;
                    let dividend = item.dividend || {};
                    return <div className="App-buyland-record-item" key={ 'record-' + index } 
                        style={{ position: 'relative' }}>
                        <img alt="" 
                            style={{ width: '18px',borderRadius: '10px', marginRight: '5px', verticalAlign: 'middle' }} 
                            src={ unionImagePath(args.unionId) } />
                        <span>{ shortString(args.owner, 14) }</span> 
                        
                        <span style={{ margin: '0 10px' }}>spent</span>
                        
                        <span className="price">
                            <CreateIcon type="zeta" size="20" /> { ' ' }
                            { showPriceData(args.currentPrice, 18, 4) } 
                        </span>
                        
                        <span style={{ margin: '0 0 0 5px' }}>biding for </span>
                        
                        <span className="plot">
                            { currentType === 'Mine' && <> 
                                {item.lastOwner === address && <>your </> }
                                {item.lastOwner && item.lastOwner !== address && <span style={{ margin: '0 5px 0 0' }}>
                                    { shortString(item.lastOwner, 14) }
                                </span> }
                            </>}
                            { currentType !== 'Mine' && item.lastOwner && <span style={{ margin: '0 5px 0 0' }}>
                                { shortString(item.lastOwner || '', 14) }
                            </span>}
                            <CreateIcon type="plot" size="18" />
                            plot({args.x},{args.y}) 
                        </span>

                        at { dateFormat(item.timestamp, 'yyyy.MM.dd hh:mm:ss') }.

                        { currentType === 'Mine' && (args.owner !== address) && <>
                            { item['refund'] &&
                                <span style={{ margin: '0 0 0 5px' }}>
                                    <span className="price">
                                        <CreateIcon type="zeta" size="20" /> { ' ' } 
                                        { showPriceData(item['refund'], 18, 4) } 
                                    </span>
                                    transfer to your wallet
                                </span>
                            }
                            { dividend[address] && <> 
                                { item['refund'] ? ' and you ' : ' You ' }
                                received <span className="price">
                                <CreateIcon type="zeta" size="20" /> { ' ' }
                                { showPriceData(dividend[address], 18, 4) } 
                                </span> stock dividend.
                            </>}
                        </>}

                        <a className="App-zetaTransactionLink" 
                            style={{ 
                                margin: '-5px 0 0 10px', 
                                verticalAlign: 'middle',
                                position: 'absolute',
                                right: '20px',
                                top: '15px'
                            }}
                            href={ zetaTransactionLink + item.hash } rel="noreferrer" target="_blank">
                            { shortString(item.hash, 20) }
                        </a>
                    </div>
                })}
            </div>
            </div>
        </div>
    </Modal>);
}
