import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import * as DASlices from '../../da/slice';

import NumericFormat from 'react-number-format';
import { GoBack, CreateIcon, Slider, ClockCounter } from '../../components'
import { NotifyPop, ToastTip } from '../../components'

import { Texts, Texts2, GameTexts } from '../../const';
import { showPriceData } from '../../utils/utils';

export default function RewardInSeason(props){
    const dispatch = useDispatch();
    const { season = {}, seasons = [], onWalletSelect, address } = props; 

    const { gameStart, shareInfo, allShareInfo, opResults } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId } = gameStart;
    const seasonId = seasonInfo.sid;

    let _shareInfo = shareInfo[seasonId] || {};
    let totalDivident = _shareInfo.totalDivident || 0;
    // let unionTotalShare = _shareInfo.unionTotalShare || 0;

    useEffect(()=>{
        if(!address){
            return;
        }
        dispatch(DASlices.getShareInfo({
            address,
            seasonId,
            unionId
        }));
    }, [seasonId, unionId, address, opResults]);

    return (<>
        <span className="App-reward-claim-area" onClick={ ()=>{ 
            if(address){return}
            onWalletSelect && onWalletSelect();
            } }>
            <CreateIcon type="zeta" size="34" />
            <span className="App-reward-claim">
                <span className="reward-label">Reward</span>
                <span className="reward-total">
                    {
                        address ? <NumericFormat value={ showPriceData(totalDivident, 18, 4) } displayType="text" thousandSeparator="," /> : '--'
                    }
                </span>
            </span> 
        </span>

        <a className="App-loading-btn App-menu-link">
            {address
            ?  <span 
                title =  { seasonId }
                className={"App-general-btn App-general-btn-green" + (totalDivident == 0 ? ' App-btn-disable' : '')} 
                onClick={ (event)=>{
                    event.stopPropagation();
                    if(totalDivident == 0){
                        return;
                    }
                    dispatch(DASlices.withdrawDividends({
                        address,
                        seasonId
                    }));
                } }>
                Withdraw
            </span>
            : <span 
                className="App-general-btn App-general-btn-green App-btn-disable" 
                onClick={ ()=>{ onWalletSelect && onWalletSelect(); } }>
                Withdraw
            </span>}
        </a>

        {opResults.back && opResults.type === 'withdrawDividends' && <ToastTip 
            text={ opResults.result ? 'withdraw successful.' : 'withdraw fail.' } 
            callback={ ()=>{ 
                dispatch(DASlices.resetResult()); 
            }
        } />}
    </>);
}