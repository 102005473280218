import svg  from 'svg';
import { staminaTimes } from 'throne-underlying';

function getStamina(type){
    let types = {
        'attackCity': 'attackPlayer',
        'attackBlock': 'attackPlots',
        'defenseBlock': 'defensePlots',
        'miningSilvers': 'gather',
    };
    return staminaTimes[type] || staminaTimes[types[type] || 'empty'] || 0;
}

function timeFormat(time){
    //time == s; 1D11H56M23S;
    if(!time){
        return '';
    }
    if(time === 0){
        return 0;
    }
    time = Math.round(time);
    let d = Math.floor(time/3600/24);
    let h = Math.floor((time/3600 - d*24));
    let m = Math.floor((time/60 - d*24*60 - h*60));
    let s = Math.floor((time - d*3600*24 - h*3600 - m*60));
    let str = s + 'S ';
    if(m > 0){
        str = m + 'M ' + str;
    }
    if(h > 0){
        str = h + 'H ' + str;
    }
    if(d > 0){
        str = d + 'D ' + str;
    }
    return  str;
}

function numberFormat(n){
    //1000 == 1.00K, >1000000 == 1.00M
    if(!n || n < 0){
        return 0;
    }
    let d = n > 0 ? '' : '-';
    n = Math.abs(n);
    if(n >= 1000000000){
        return d + '9999M';
    }if(n >= 1000000){
        return d +  Math.floor(n/10000)/100 + 'M';
    }else if(n >= 1000){
        return d + Math.floor(n/10)/100 + 'K';
    }else{
        return d + Math.floor(n);
    }
}

function numberFormatDicimal(n, dn){
    //1000 == 1.00K, >1000000 == 1.00M
    if(!n || n < 0){
        return 0;
    }
    let d = n > 0 ? '' : '-';
    n = Math.abs(n);
    if(n >= 1000000000){
        return d + '9999M';
    }if(n >= 1000000){
        return d +  Math.floor(n/10000)/100 + 'M';
    }else if(n >= 1000){
        return d + Math.floor(n/10)/100 + 'K';
    }else{
        return d + Math.floor(n* Math.pow(10, dn))/Math.pow(10, dn);
    }
}

function dateFormat(date, fmt) {
    fmt = fmt || 'yyyy.MM.dd hh:mm:ss';
    var dateObj;
    if(date){
        dateObj = new Date(date);
    }else{
        return '';
    }

    var o = {
        "M+" : dateObj.getMonth()+1,                 
        "d+" : dateObj.getDate(),                    
        "h+" : dateObj.getHours(),                   
        "m+" : dateObj.getMinutes(),                 
        "s+" : dateObj.getSeconds(),                 
        "q+" : Math.floor((dateObj.getMonth()+3)/3), 
        "S"  : dateObj.getMilliseconds()             
    };
    // var o = {
    //     "M+" : dateObj.getUTCMonth()+1,                 
    //     "d+" : dateObj.getUTCDate(),                    
    //     "h+" : dateObj.getUTCHours(),                   
    //     "m+" : dateObj.getUTCMinutes(),                 
    //     "s+" : dateObj.getUTCSeconds(),                 
    //     "q+" : Math.floor((dateObj.getUTCMonth()+3)/3), 
    //     "S"  : dateObj.getUTCMilliseconds()             
    // };
    if(/(y+)/.test(fmt)) {
        fmt=fmt.replace(RegExp.$1, (dateObj.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    for(var k in o) {
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length===1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt;
}
function dayTimeFormat(time){
    let date = dateFormat(time, 'MM-dd hh:mm');
    let h = dateFormat(time, 'hh')/1;
    return date + ' ' + ( h < 12 ? 'AM': 'PM');
}

function createRadarSvg(skillsData){
    //side = 600;
    //value = 200;
    let points = {
        "v1":[[110,125],[110,141],[110,157],[110,173],[110,189],[110,205],[110,221],[110,237]],
        "v2":[[110,125],[124,133],[138,141],[152,149],[165,157],[179,165],[193,173],[207,181]],
        "v3":[[110,125],[124,117],[138,109],[152,101],[165,93],[179,85],[193,77],[207,69]],
        "v4":[[110,125],[110,109],[110,93],[110,77],[110,61],[110,45],[110,29],[110,13]],
        "v5":[[110,125],[96,117],[82,109],[68,101],[55,93],[41,85],[27,77],[13,69]],
        "v6":[[110,125],[96,133],[82,141],[68,149],[55,157],[41,165],[27,173],[13,181]]
    };

    let result = [];
    skillsData.forEach(function(_skill, index){
        let d = Math.round(_skill.value*3/100);
        result.push(points['v' + (index + 1)][d]);
    });

    var text = '<svg width="220" height="250" viewPort="0 0 220 250" version="1.1" xmlns="http://www.w3.org/2000/svg">'
     + '<polygon points="' + result.join(' ') + '" style="stroke:green; fill:rgba(0, 234, 255, 1); stroke-width: 0;" />'
     + '</svg>';
    let elem = svg(text);
    return elem;
}

function subs(temp, data, regexp){
    if(!(Object.prototype.toString.call(data) === "[object Array]")) data = [data];
    var ret = [];
    for (var i = 0, j = data.length; i < j; i++) {
        ret.push(replaceAction(data[i]));
    }
    return ret.join("");
    function replaceAction(object){
        return temp.replace(regexp || (/\\?\%{([^}]+)\}/g), function(match, name){
            if (match.charAt(0) === '\\') return match.slice(1);
            return (object[name] !== undefined) ? object[name] : '';
        });
    }
}


function getPara(url,name){
    url = url.split("#")[0];
    if(url == ''){
        return '';
    }

    var v = '', _p = name + '=';

    if(url.indexOf("&" + _p)>-1){
        v = url.split("&" + _p)[1] || '';
    }

    if(url.indexOf("?" + _p)>-1){
        v = url.split("?" + _p)[1] || '';
    }
    v = v.split("&")[0] || '';
    return v;
}

function setPara(url,name,value){
    url = url.split("#")[0];
    var paras = name + "=" + value;
    var v = getPara(url,name);
    return v == "" ? (url + ((url.indexOf("?")<0) ? "?" : "&") + paras) : url.replace("&"+name+"="+v,"&"+paras).replace("?"+name+"="+v,"?"+paras);
}

function getStringLength(str) {  
    str = str || '';
    var len = 0;    
    for (var i=0; i<str.length; i++) {    
        if (str.charCodeAt(i)>127 || str.charCodeAt(i)===94) {    
             len += 2;    
         } else {    
             len ++;    
         }    
     }    
    return len;    
}

function getWalletErrMessage(str){
    console.log('getWalletErrMessage', str);
    let msg = 'Wallet internal error';
    if(!str){
        return msg;
    }
    var data = str;
    if(typeof str == 'string'){
        str = str || '';
        data = str.replace('Internal JSON-RPC error.\n', '');
        try{
            data = JSON.parse(data || '{}');
        }catch(err){
            data = {};
        }
    }
    console.log('getWalletErrMessage', str, data);
    str = data.message || str;
    if(str.indexOf('desc = ') > -1){
        str = str.split('desc = ')[1];
    }
    return str; 
}


function getWalletSignErrMessage(res, type){
    let errs = {
        sign: 'You have cancelled your sign-up.',
        play: 'Entering the game has been cancelled.',
        recharge: 'Recharge has been cancelled.',
        bid: 'Bid has been cancelled.'
    };
    let msg = 'Wallet internal error';

    console.log('getWalletErrMessage', res);

    res = res || {};
    let data = res.data || {};
    if(data.code === 4001){
        return errs[type] || data.message || msg;
    }

    return data.message || res.message || res.msg || msg;
    // var data = str;
    // if(typeof str == 'string'){
    //     str = str || '';
    //     data = str.replace('Internal JSON-RPC error.\n', '');
    //     try{
    //         data = JSON.parse(data || '{}');
    //     }catch(err){
    //         data = {};
    //     }
    // }
    // str = data.message || str;
    // if(str.indexOf('desc = ') > -1){
    //     str = str.split('desc = ')[1];
    // }
    // return str; 
}


function showPriceData(n, dm, len){
    dm = dm || 18;
    len = len || 2;
    
    n = n/Math.pow(10, dm);

    n = Math.round(n * Math.pow(10, len))/Math.pow(10, len);
    return n;
}

export {
    timeFormat,
    dayTimeFormat,
    dateFormat,
    numberFormat,
    numberFormatDicimal,
    createRadarSvg,
    subs,
    getPara,
    setPara,
    getStringLength,
    getStamina,
    getWalletErrMessage,
    getWalletSignErrMessage,
    
    showPriceData
}