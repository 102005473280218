import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { CreateIcon, GoBack } from '../../components'
import Modal from 'react-modal';

export default function MiningRule(props){
    const dispatch = useDispatch(); 

    const [infoPop, setInfoPop] = useState(false);

    useEffect(()=>{
    }, []);


    return (<>
        <span className="tips" 
            style={{ color: '#d9d5cb', cursor: 'pointer' }}
            _ga_event="auction.mining.rule.open" onClick={ ()=> { setInfoPop(true) }}>
            <span style={{ top: '-2px', left: '-2px', position: 'relative' }}>
                <CreateIcon type="info-tips" size="18" />
            </span> 
            { 'Mining Rule' }
        </span>

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '300px',
                    width: '600px',
                    margin: '-40px 0 0 -300px'
                }
            }}
            className = { 'App-game-modal-sign-overview App-da-modal' }
            isOpen={ infoPop }
            onRequestClose = { () =>{ setInfoPop(false) } }
            contentLabel="Camp Overview">
            <div className="App-game-modal-sign-overview-content">    
                <div _ga_event={ "auction.mining.rule.close" } 
                    style={{ position: 'absolute', margin: '-20px 0 0 20px' }}
                    onClick={ ()=>{ setInfoPop(false) } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-content1" style={{ padding: '40px' }}>
                    Occupy plots of land through Dutch auctions. Each plot has a starting price, and the price will gradually decrease over time until it reaches the minimum price. The price is reduced by 10% every 300 seconds.<br />
                    Plots are open for auction only to DAOs that own adjacent plots. After a plot is auctioned, there will be a protection period, during which the plot cannot be auctioned again.<br />
                    Each plot has stock. You can obtain stock by occupying the plot, and the stock dividends are affected by the total number of stock currently owned by the player.<br />
                    When your plot is auctioned, the funds you spent bidding on the current plot will be returned to your wallet.<br />
                </div>
            </div>
        </Modal>
    </>);
}


function getDailyData(kLineData, landInfo, seasonInfo){
    let dates = [];
    let data = [];
    let unionIds = [];
    var minY = 100000;
    var maxY = 0.00001;
    let last = 0;

    let timestamp = seasonInfo.timestamp/1 + 1000 ;
    let price = showPriceData(landInfo.nextLandPrice/1 || 0, 18, 4);
    // dates.push(dateFormat(timestamp, 'M-d hh:mm'));
    // data.push(price);
    // unionIds.push(landInfo.unionId);
    // minY = Math.min(minY, price);
    // maxY = Math.max(maxY, price);

    kLineData.forEach(function(item, index){
        let price = showPriceData(item.args.currentPrice/1, 18, 4);
        dates.push(dateFormat(item.timestamp, 'M-d hh:mm'));
        data.push(price);
        unionIds.push(item.args.unionId);
        minY = Math.min(minY, price);
        maxY = Math.max(maxY, price);
    });
    return { dates, data, minY, maxY, unionIds }
}

function DailyTransactions(props){
    let grid = {
        w: '620px',
        h: '225px',
    };
    let { w, h } = grid;

    let { dates, data, unionIds, minY, maxY } = props.data || {};
    let min = Math.floor(minY/100)*100;

    let option = {
        grid: {
            left: '10px',
            right: '10px',
            top: '18px',
            bottom: '25px'
        },
        textStyle: {
            color: '#a79074'
        },
        xAxis: {
            data: dates,
            type: 'category',
            axisLine: {
                show: false
            },
            axisLabel: {
                show: true,
                margin: 4,
                padding: [0, 0, 0, 0],
                align: 'left',
                rotate: 0,
                alignMinLabel: 'left'
            },
            axisTick: {
                show: false
            },
            splitNumber: 20,
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#6c4434'],
                    width: 1,
                    type: 'solid'
                }
            }
        },
        yAxis: {
            type: 'value',
            splitNumber: 10,
            interval: maxY/10,
            // min: 0,
            // max: 1,
            axisTick: {
                show: false
            },
            axisLabel: {
                show: false
            },
            splitLine: {
                show: true,
                lineStyle: {
                    color: ['#6c4434'],
                    width: 1,
                    type: 'solid'
                }
            }
        },
        tooltip: {
            trigger: 'item',
            backgroundColor: 'transparent',
            border: 'none',
            formatter: '<span style="margin: -10px 0 0 -50px; border:0">{c} Zeta</span>',
            textStyle: {
                color: '#fff'
            }
        },
        legend: {},
        series: [
            {
                data: data,
                type: 'line',
                symbol: function(value, params){
                    let index = params.dataIndex;
                    let unionId = unionIds[index];
                    return 'image://' + unionImagePath(unionId);
                },
                symbolSize: 16,
                itemStyle: {
                    color: 'rgba(167, 29, 1, 0.1)'
                },
                lineStyle: {
                    color: 'rgba(255, 162, 0, 1)',
                    width: 2.5
                },
                smooth: true
            }
        ]
    };

    if(data.length > 0){
        option.series[0].markLine = {
            data: [{ type: 'max', name: 'Max' }],
            label: { show: true, position: 'insideEndTop' },
            tooltip: { show: false },
            lineStyle: {
                color: '#f5f5f5',
                width: 1
            },
            emphasis: { disabled: true , label:{ show: false } },
            symbolSize: 0
        };
    }

    useEffect(()=>{
        var chartDom = document.querySelector('.App-acution-dailyTransactions');
        var myChart = window.echarts.init(chartDom);
            myChart.setOption(option);
    }, []);

    return(
        <div className="App-acution-dailyTransactions" style={{
            width: w,
            height: h
        }}>
        </div>
    );
}