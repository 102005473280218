import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import NumericFormat from 'react-number-format';
import RewardInSeason from './reward-in-season';
import * as DASlices from '../../da/slice';

import RewardRecords from './reward-records';

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, timeFormat, subs, showPriceData } from '../../utils/utils'
import { unionImagePath, shortString } from '../../utils/ui-res'

import Modal from 'react-modal';

export default function RewardPool(props) {
    const dispatch = useDispatch(); 
  
    const { gameStart, gameInfo, rewardPoolConfig } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;
    const seasonId = seasonInfo.sid;
    let _gameInfo = gameInfo[seasonId] || {};

    useEffect(()=>{
        if(!address || !seasonId || !unionId){
            return;
        }

        let params = {
            unionId,
            seasonId,
            address
        };
        dispatch(DASlices.getGameInfo(params));
    }, [gameStart]);

    const [poolInfoPop, setPoolInfoPop] = useState(false);
    const [rewardRecordsPop, setRewardRecordsPop] = useState(false);

    return (<>
        <div className="App-reward-bar App-reward-pool" onClick={ ()=>{
            setPoolInfoPop(true);
            } }>
            <div className="App-reward-pool-item">
                <label>Conquest Reward</label>
                <span>
                    <CreateIcon type="zeta" />&nbsp;
                    <NumericFormat value={ showPriceData(_gameInfo.totalUnionPoolValue || 0, 18, 4) } displayType="text" thousandSeparator="," /> &nbsp;&nbsp;
                </span>
            </div>
            <div className="App-reward-pool-item-barry"></div>
            <div className="App-reward-pool-item">
                <label>Individual Reward</label>
                <span>
                    <CreateIcon type="zeta" /> &nbsp;
                    <NumericFormat value={ showPriceData(_gameInfo.totalFinalPoolValue || 0, 18, 4) } displayType="text" thousandSeparator="," /> &nbsp;&nbsp;
                </span>
            </div>
        </div>

        <div className="App-reward-bar App-da-MyRewards"
            _ga_event="auction.reward.records.open"
            onClick={ ()=>{ setRewardRecordsPop(true) } }>
            <RewardInSeason address={ address } season={ seasonInfo } onWalletSelect={ ()=>{ 
            } } />
        </div>
        {rewardRecordsPop && <RewardRecords data={ { seasonId, address } } onClose={ ()=>{
            setRewardRecordsPop(false)
        } } />}

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '400px',
                    width: '600px',
                    margin: '-200px 0 0 -300px'
                }
            }}
            className = { 'App-game-modal-sign-overview App-da-modal' }
            isOpen={ poolInfoPop }
            onRequestClose = { () =>{ setPoolInfoPop(false) } }
            contentLabel="Camp Overview">
            <div className="App-game-modal-sign-overview-content">    
                <div _ga_event={ "auction.reward.pool.close" } 
                    style={{ position: 'absolute', margin: '-20px 0 0 20px' }}
                    onClick={ ()=>{ setPoolInfoPop(false) } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-content1" style={{ padding: '40px' }}>
                    There are four prize pools: Dividends for plot owners Pool, Winning DAO Pool, Individual Winner Rewards and Active Reward Pool<br />
                    Current dividend pool: players are given dividends in real time according to the equity ratio of all players in the current season <br />
                    Conquest reward pool: after the season ends, all players in the winning camp will receive dividends according to the equity ratio <br />
                    Individual reward pool : after the season ends, the players who occupy the capital will receive all the final grand <br />
                    Active reward pool: After the season ends, all players who have completed and obtained active points will receive dividends in proportion to their equity.<br />
                    The cost of bidding for plot parcels will be injected into each prize pool in proportion, as ratio: <br />
                    <div style={{ height: '10px' }}></div>
                    Current dividend pool: { rewardPoolConfig.dividend } <br />
                    Conquest reward pool: { rewardPoolConfig.conquest } <br />
                    Individual reward pool: { rewardPoolConfig.individual } <br />
                    Active pool: { rewardPoolConfig.active } <br />
                    Developer pool: { rewardPoolConfig.developer } <br />
                </div>
            </div>
        </Modal>
    </>);
}
