import React, {useEffect,useState} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Phaser from 'phaser';

import NumericFormat from 'react-number-format';
import RewardInSeason from './reward-in-season';
import * as DASlices from '../../da/slice';

import { GoBack, CreateIcon, ProgressBar, ClockCounter } from '../../components'
import { NotifyPop, ToastTip, Loading } from '../../components'

import { numberFormat, dateFormat, subs, showPriceData } from '../../utils/utils'
import { unionImagePath, shortString } from '../../utils/ui-res'

import Modal from 'react-modal';

export default function RewardRecords(props) {
    const dispatch = useDispatch(); 

    const { address, seasonId } = props.data || {};
  
    const { claimedRecords } = useSelector(function(state){
        return state.dauction;
    });
    let { data = [], totalCnt = 0 } = claimedRecords;
    console.log(claimedRecords)

    const [pageNumber, setPageNumber] = useState(0);
    let pageSize = 100;

    useEffect(()=>{
        if(!address || !seasonId){
            return;
        }

        let params = {
            seasonId,
            address,
            pageNumber,
            pageSize
        };
        dispatch(DASlices.getClaimedRecords(params));
    }, [address, seasonId, pageNumber]);

    return (<>
        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '470px',
                    width: '600px',
                    margin: '-240px 0 0 -300px'
                }
            }}
            className = { 'App-game-modal-sign-overview App-da-modal' }
            isOpen={ true }
            onRequestClose = { () =>{ props.onClose && props.onClose() } }
            contentLabel="Camp Overview">
            <div className="App-game-modal-sign-overview-content">    
                <div _ga_event={ "auction.reward.records.close" } 
                    style={{ position: 'absolute', margin: '-20px 0 0 20px' }}
                    onClick={ ()=>{ props.onClose && props.onClose() } }>
                    <GoBack size="60px" />
                </div>
                <div className="App-game-modal-content1" style={{ padding: '30px 40px 20px' }}>
                <div style={{ height: '300px', overflow: 'auto' }}>
                    <p style={{ margin: '20px 0', fontSize: '18px' }}>Conquest rewards and individual rewards will be collected manually on the game login interface after the quarter ends.</p>
                    
                    {data.map((item, index) => {
                        let { address, reward = 0, ts } = item;
                        return <div key={ 'record-' + index }
                            style={{ padding: '8px' }}
                            className="App-buyland-record-item" >
                            You withdrawed 
                            <span className="price">
                                <CreateIcon type="zeta" size="20" /> { ' ' }
                                { showPriceData(reward, 18, 4) } 
                            </span> 
                            rewards at { dateFormat(ts || '', 'yyyy.MM.dd hh:mm:ss') }.
                        </div>
                    })}

                    {data.length === 0 && <p style={{ textAlign: 'center', padding: '50px' }}>No any claimed record.</p>}
                </div>
                </div>
            </div>
        </Modal>
    </>);
}
