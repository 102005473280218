import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { isProd } from '../../utils/ui-res'
import { logTrace } from '../../utils/log'

import { Texts2, GameTexts, zetaContractExplorer } from '../../const';
import contractImg from './assets/contract.png';

import { GoBack, CreateIcon } from '../../components'

import AppGame from '../../const/app.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function Header(props){
    const dispatch = useDispatch(); 
    const { isHistorySeason, address } = props;

    const [zetaChainPop, setZetaChainPop] = useState(false);

    return (<>
        {isHistorySeason 
        ? <div className="App-season-menus-left"></div> 
        : <div className="App-season-menus-left">
            <a href={ AppGame.daGuidebook } target="_blank" className="App-loading-btn App-menu-link">
                <span className="App-general-btn App-general-btn-blue">
                    { 'Guidebook' }
                </span>
            </a>

            <a href='/dutch-auction/?type=end' target="_blank" className="App-loading-btn App-menu-link">
                <span className="App-general-btn App-general-btn-blue">
                    { Texts2['button.history'] }
                </span>
            </a>

            <span className="App-general-btn App-general-btn-blue"
                onClick={ ()=>{ setZetaChainPop(true) } } 
                style={{ marginLeft: '50px' }}>
                { 'Zeta Chain' }
            </span>
        </div>}

        <Modal
            style = {{
                overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
                content: {
                    height: '600px',
                    marginTop: '-300px'
                }
            }}
            className = { 'App-game-modal-sign-overview App-game-modal-gray' }
            isOpen={ zetaChainPop }
            onRequestClose = { () =>{ setZetaChainPop(false) } }
            contentLabel="Camp Overview">
            <div className="App-game-modal-sign-overview-content2" style={{ position: 'relative' }}>
                <div className="App-game-modal-header" style={{ lineHeight: '4rem' }}>Zeta Chain Search</div>
                <div
                    style={{ position: 'absolute', margin: '-55px 0 0 20px' }} 
                    _ga_event={ "auction.events.mine.close" } 
                    onClick={ ()=>{ setZetaChainPop(false) } }>
                    <GoBack size="60px" />
                </div>

                <div style={{ width: '90%', margin: '10px 0 0 55px', border: '2px solid #fff', fontSize: 0, borderRadius: '5px', textAlign: 'center' }}>
                    <a href={ zetaContractExplorer } target="_blank">
                        <img src={ contractImg } style={{ width: '100%' }} />
                    </a>
                </div>

                <div style={{ textAlign: 'center', marginLeft: '35px' }}>
                    <p style={{ lineHeight: 3 }}>Enter your wallet and season ID in the position in the picture to view the rewards obtained in that season.</p>
                    <a href={ zetaContractExplorer } target="_blank" className="App-loading-btn App-menu-link">
                        <span className="App-general-btn App-general-btn-blue">
                            { 'goto' }
                        </span>
                    </a>
                </div>
            </div>
        </Modal>
    </>);
}

