import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';

import { getLastTime } from '../../utils/ui-res'
import { numberFormat, showPriceData, dateFormat } from '../../utils/utils';
import { colors, getIdIndex, getMapGDS } from '../../maps/map-res'

import mainFortress from './assets/fortress.png';
import wartC from './assets/wart-c.svg';

import cloudImg from './assets/cloud.png';
import battleImg from './assets/battle.png';
import protectImg from './assets/protect.png';
import codImg from './assets/cod.png';

import city from './assets/city.png';
import castleImg from './assets/castle.png';

import crown1Img from './assets/crown1.png';
import zetaImg from './assets/zeta.png';
import shareImg from './assets/camp.png';
import shadowImg from './assets/shadow.png';

import { logTrace } from '../../utils/log'

// console.log(distancesConfig);

var mapId = 7;
var { mapGDS, distancesConfig } = getMapGDS(mapId);

let isDragging = false;

const getOccupiedIds = function(obj1, obj2){
    let removeOccupieds = {};
    let newOccupieds = {};
    let isChanged = false;

    for(var id1 in obj1){
        let owner1 = getOwner(obj1, id1);
        let owner2 = getOwner(obj2, id1);
        if(owner1 !== owner2){
            removeOccupieds[id1] = obj1[id1];
        }
    }

    for(var id2 in obj2){
        let owner1 = getOwner(obj1, id2);
        let owner2 = getOwner(obj2, id2);
        if(owner1 !== owner2){
            newOccupieds[id2] = obj2[id2];
            isChanged = true;
        }
        if(JSON.stringify(obj2[id2]) !== JSON.stringify(obj1[id2] || {})){
            newOccupieds[id2] = obj2[id2];
            isChanged = true;
        }
    }

    return { removeOccupieds, newOccupieds, isChanged };

    function getOwner(obj, id){
        let tileData = obj[id] || {};
        let owner = tileData.unionId || 0;
        return owner;
    }
}

export default class SceneBase extends Phaser.Scene {
    constructor(data, noCloud) {
        super({
            key: 'SceneBase',
            active: true
        });
        logTrace(data, 'map.SceneBase.constructor');
        // console.log(data, 'map.SceneBase.constructor');

        let { isLandscape, isMobile } = window.LeagueOfThronesConfig;
        const isRotate = isLandscape && isMobile;
        this.isRotate = isRotate;

        this.unlockedRing = data.unlockedRing;
        this.totalFinalPoolValue = data.totalFinalPoolValue;

        this.landsDetail = data.landsDetail;
        
        let occupieds = data.occupieds || {};
        let { removeOccupieds, newOccupieds } = getOccupiedIds({}, occupieds);
        this.occupieds = occupieds;
        this.newOccupieds = newOccupieds;
        this.removeOccupieds = removeOccupieds;

        this.mapConfig = data.mapConfig || {};
        this.cods = data.codsAll || {};
        this.noCloud = noCloud || false;

        this.effectsLayers1 = {}; //capitals
        this.effectsLayers2 = {}; //blocks
        this.unlockMasks = {}; //blocks
        this.codEffectsLayers = [];
        this.unlockLayers = [];
        this.priceLayers = [];
        this.campsLayers = [];
        this.isFirstMove = false;
    }

    preload() {
        let { unions, bg, map, rexdragplugin } = this.mapConfig;

        this.load.image("cloudImg", cloudImg);
        this.load.image("battleImg", battleImg);
        this.load.image("protectImg", protectImg);
        this.load.image("codImg", codImg);

        this.load.image("city", city);
        this.load.image("castleImg", castleImg);
        this.load.image("crown1Img", crown1Img);
        this.load.image("zetaImg", zetaImg);

        this.load.image("union1", unions['1']);
        this.load.image("union2", unions['2']);
        this.load.image("union3", unions['3']);
        this.load.image("union4", unions['4']);
        this.load.image("union5", unions['5']);
        this.load.image("union6", unions['6']);

        this.load.image("wartC", wartC);
        this.load.image("mainFortress", mainFortress);

        this.load.image('bgImages', bg.images);        
        this.load.tilemapTiledJSON('bgConfig', bg.config);

        this.load.image('mapImages', map.images);
        this.load.tilemapTiledJSON('mapConfig', map.config);

        this.load.plugin('rexdragplugin', rexdragplugin, true);
    }

    create() {
        let _this = this;

        let { width, height, topLeft } = this.mapConfig;

        this.cameras.main.setBounds(topLeft, topLeft, width, height);
        // var bounds = this.cameras.main.getBounds();  
        this.cameras.main.scrollX =  400;
        this.cursors = this.input.keyboard.createCursorKeys();

        //draw maps
        this.map0 = this.add.tilemap('bgConfig');
        var tileset0 = this.map0.addTilesetImage('bg', 'bgImages');
        let map0 = this.map0.createLayer('map0', tileset0).setDepth(100);

        this.map1 = this.add.tilemap('mapConfig');
        var tileset1 = this.map1.addTilesetImage('map', 'mapImages');
        let map1 = this.map1.createLayer('map1', tileset1).setDepth(200);

        this.input.on('gameover', () => {
            // document.title = 'gameover';
            this.game.events.emit("gameover", true);
        });
        this.input.on('gameout', () => {
            // document.title = 'gameout';
            this.game.events.emit("gameout", true);
        });

        window.addEventListener("touchstart", function(event){
            let target = event.target;
            if(target.tagName.toLowerCase() !== 'canvas'){
                _this.game.events.emit("gameout", true);
                // document.title = 'touch dom';
            }else{
                _this.game.events.emit("gameover", true);
                // document.title = 'touch game';
            }
        }, true);

        window.addEventListener("mousemove", function(event){
            let target = event.target;
            if(target.tagName.toLowerCase() !== 'canvas'){
                _this.game.events.emit("gameout", true);
                // document.title = 'touch dom';
            }else{
                _this.game.events.emit("gameover", true);
                // document.title = 'touch game';
            }
        }, true);

        //draw centers
        this.drawCampsInit();
        this.drawClouds();

        _this.drawOccupieds(map0, false);
        _this.drawAllLandsPrice(map0, false);
        _this.game.events.on("occupiedsChanged", function(data) {
            let { occupieds = {}, landsDetail, from, totalFinalPoolValue } = data;

            if(from === 'rewardPool'){
                _this.totalFinalPoolValue = totalFinalPoolValue;
                _this.drawCampsInit();
                return;
            }

            _this.landsDetail = landsDetail || {};
            _this.drawAllLandsPrice(map0, true);


            let occupiedsOld = _this.occupieds || {};
            let { removeOccupieds, newOccupieds, isChanged } = getOccupiedIds(occupiedsOld, occupieds);
            if(!isChanged){
                return;
            }
            _this.occupieds = occupieds;
            _this.newOccupieds = newOccupieds;
            _this.removeOccupieds = removeOccupieds;

            _this.drawOccupieds(map0, true);
        });

        _this.drawCods(map0, false);
        _this.game.events.on("codsChanged", function(data) {
            let { cods, from } = data;
            _this.cods = cods;
            _this.drawCods(map0, true);
        });


        _this.game.events.on("unlockedRingChanged", function(data) {
            let { unlockedRing } = data;
            let masks = _this.unlockMasks[unlockedRing];
            masks.forEach(function(_mask){
                _mask.destroy();
            });
            _this.unlockMasks[unlockedRing] = [];
        });

        this.setMove(this);

        //move camera by wheel
        this.input.on("wheel", function(pointer, currentlyOver, deltaX, deltaY, deltaZ){
            const delta = 30; //if unit = 30
            this.cameras.main.scrollY += (deltaY * -0.01 * delta); 
            this.cameras.main.scrollX += (deltaX * -0.01 * delta); 
        },this);    

        // This will trigger the scene as now being ready.
        this.game.events.emit("READY", true);
    }

    drawClouds(){
        let { width, height } = this.mapConfig;

        let canvasW = width;
        let canvasH = height;

        if(this.noCloud){
            return;
        }
        let _this = this;
        let w = 360, h = 240;
        let n1 = Math.ceil(canvasW/w);
        let n2 = Math.ceil(canvasH/w);
        let diff = 0;
        for(var i=0;i<n1;i++){
            _this.add.image(w*i - i*diff, 70, 'cloudImg').setDepth(129);         
            _this.add.image(w*i - i*diff, canvasH - 140, 'cloudImg').setRotation(Math.PI).setDepth(129); 
        }
        for(var i=0;i<n2;i++){
            _this.add.image(0, w*i - i*diff, 'cloudImg').setRotation(Math.PI*1.5).setDepth(129);         
            _this.add.image(canvasW - w/2 + 10, w*i - i*diff, 'cloudImg').setRotation(Math.PI*0.5).setDepth(129); 
        }
    }

    drawCampsInit() {
        let _this = this;
        let { tileWidth, tileHeight, width, height, rows, cols, degNumber, graphicsSkew, xAxis, yAxis, capitalType } = this.mapConfig;

        _this.destroyCampsLayers();

        let capitals = this.mapConfig['capitals'];
        let inits = this.mapConfig['inits'];
        // let ports = this.mapConfig['ports'];
        
        let diff = tileWidth/3.2;

        // if(ports){
        //     for(var blockId in ports){
        //         let { x, y } = ports[blockId];
        //         console.log('ports', blockId, x, y);
        //         let portBlock = _this.map0.getTileAt(x, y);
        //         _this.add.image(portBlock.pixelX + diff, portBlock.pixelY + diff -10, 'portImg').setScale(0.45).setDepth(210).setAngle(degNumber);            
        //     }
        // }

        if(capitalType !== 'togather'){
            for(var blockId in capitals){
                let { x, y } = capitals[blockId];
                let mainBlock = _this.map0.getTileAt(x, y);
                if(mainBlock){
                    let capitaLayer = _this.add.image(mainBlock.pixelX + diff - 3, mainBlock.pixelY + diff - 5, 'castleImg').setScale(0.42).setDepth(210).setAngle(degNumber);                    
                    _this.campsLayers.push(capitaLayer);
                }
            }
        }else{
            let mX = (xAxis - 1)/2;
            let mY = (yAxis - 2)/2;
            let mainBlock = _this.map0.getTileAt(mX, mY);
            let capitaLayer = _this.add.image(mainBlock.pixelX + diff, mainBlock.pixelY + diff, 'mainFortress').setDepth(210).setAngle(degNumber);            
            _this.campsLayers.push(capitaLayer);
        }
        if(capitalType === 'auction'){
            let mX = (xAxis - 1)/2;
            let mY = (yAxis - 2)/2;
            let mainBlock = _this.map0.getTileAt(mX, mY);

            let totalFinalPoolValue = _this.totalFinalPoolValue;
            let rewardLabel = 'Individual\nReward';
            // _this.add.text(mainBlock.pixelX + 90, mainBlock.pixelY + 10, rewardLabel , { fontSize: '14px', fill: '#F0C067' }).setDepth(210).setAngle(degNumber); 
            // _this.add.image(mainBlock.pixelX + 96, mainBlock.pixelY + 50, 'zetaImg').setScale(0.06).setDepth(211).setAngle(degNumber);
            // _this.add.text(mainBlock.pixelX + 110, mainBlock.pixelY + 43, totalFinalPoolValue , { fontSize: '14px', fill: '#fff' }).setDepth(210).setAngle(degNumber); 

            let castleLayer = _this.add.image(mainBlock.pixelX + diff, mainBlock.pixelY + diff, 'castleImg').setScale(0.42).setDepth(210).setAngle(degNumber);            
            _this.campsLayers.push(castleLayer);

            //https://rexrainbow.github.io/phaser3-rex-notes/docs/site/domelement/
            let style1 = "background: rgba(0,0,0, 0.4) url(" + crown1Img + ") no-repeat 2px 2px;background-size:auto 90%;color: #F0C067;padding: 5px 9px 20px 3.5rem;border-radius: 5px;font-size: 13px;z-index: 999999"
            let style2 = "background: url(" + zetaImg + ") no-repeat 2px 2px;background-size: auto 90%;color: #fff; margin: 5px 0 0 10px; padding: 2px 2px 2px 1.5rem;border-radius: 1px;font-size: 13px;z-index: 999999";
            let htmlString = '<div style="' + style1 + '">'
                + rewardLabel
                + '<div style="' + style2 + '">' + totalFinalPoolValue + '</div>' 
                + '</div>' ;

            let startX = mainBlock.pixelX + diff - 2;
            let startY = mainBlock.pixelY + diff - 30;
            let c1 = this.add.dom(startX, startY).createFromHTML(htmlString).setDepth(211).setAngle(degNumber);
            _this.tilePop(c1, { x: 0, y: 0 });
            _this.campsLayers.push(c1);

            let largePT = 0.7;
            let dir = 1;
            let t = setInterval(function(){
                if(largePT >= 0.8){
                    dir = -1;
                }
                if(largePT <= 0.6){
                    dir = 1;
                }
                largePT += 0.04*dir;
                // clearInterval(t);
                c1.setScale(largePT);
                // c2.setScale(largePT);
            }, 130);
        }

        if(capitalType !== 'togather' || capitalType === 'auction'){
            for(var unionId in inits){
                let { x, y } = inits[unionId];
                let initBlock = _this.map0.getTileAt(x, y);
                if(initBlock){
                    let capitaLayer = _this.add.image(initBlock.pixelX + diff, initBlock.pixelY + diff, 'union' + unionId).setScale(1.28).setDepth(210).setAngle(degNumber);  //northeast                    
                    _this.campsLayers.push(capitaLayer);
                }
            }
            return;
        }

        let idsList = [
            { x: xAxis - 1, y: 0 },
            { x: 0, y: 0 },
            { x: 0, y: yAxis - 2 },
            { x: xAxis - 1, y: yAxis - 2 }
        ];
        for(var i = 1;i < 5;i++){
            let { x, y } = idsList[i - 1];
            let initBlock = this.map0.getTileAt(x, y);
            // console.log('idsList', {x, y}, initBlock);
            if(initBlock){
                let capitaLayer = this.add.image(initBlock.pixelX + diff, initBlock.pixelY + diff, getUnionKey(i)).setScale(1.28).setDepth(210).setAngle(degNumber);  //northeast                
                _this.campsLayers.push(capitaLayer);
            }
        }

        function getUnionKey(index){
            // index = 1,2,3,4
            if(degNumber > 0){
                return 'union' + (index === 4 ? 1 : index + 1);
            }else{
                return 'union' + index;
            }
        }
    }

    tilePop(target, pos){
        let _this = this;
        let { mountains } = _this.mapConfig;
        // console.log('capitalPop', target);
        target.node.addEventListener('click', function (gameObject) {
            let {x, y} = pos;
            let xyIndex = x + '^' + y;

            let unlockedRing = _this.unlockedRing;
            let distance = distancesConfig[xyIndex] || 0;

            if(unlockedRing > distance){
                return;
            }

            _this.game.events.emit("TileClick", {
                pop: true,
                data: {
                    x: x,
                    y: y,
                    isBlock: !!mountains[xyIndex] || false,
                    xyIndex: xyIndex,
                    target
                }
            });
        }, false);
    }

    destroyCampsLayers(){
        let layers = this.campsLayers;
        layers.forEach(function(img){
            img.destroy();
        });
        this.campsLayers = [];
    }

    //isUpdate after occupiedsChanged
    drawOccupieds(map, isUpdate){
        if(!map){
            return;
        }
        let _this = this;
        let { width, height, cols, rows, xAxis, yAxis, capitals, capitalType } = this.mapConfig;
        
        let centerTiles = {
            '0^2': true,
            '0^0': true,
            '0^-2': true,
            '-1^1': true,
            '-1^-1': true,
            '1^1': true,
            '1^-1': true,
        };

        let occupieds = _this.occupieds || {};
        let removeOccupieds = _this.removeOccupieds || {};
        _this.destroyEffectsLayersByIds(removeOccupieds);
        _this.destroyUnlockLayers();

        if(capitalType == 'togather'){
            _this.drawCenterEffects(centerTiles);
        }

        let newOccupieds = _this.newOccupieds || {};
        let showTileId = window.location.href.indexOf('showTileId') > -1;
        let { isLandscape, isMobile } = window.LeagueOfThronesConfig;
        let isRotate = isLandscape && isMobile;

        let unlockedRing = this.unlockedRing;

        for(var y = 0; y < yAxis; y++ ){
            for(var x = 0; x < xAxis; x++ ){
                let tile = map.getTileAt(x, y);
                let ids = getIdIndex(x, y, { cols, rows }, isRotate);
                let xyIndex = ids.x_id + '^' + ids.y_id;
                let tileData = newOccupieds[xyIndex] || {};
                // console.log('removeOccupieds newOccupieds', tile, newOccupieds, xyIndex, ids, tileData)
                let owner = tileData.unionId || 0;
                if(tile){
                    if(showTileId){
                        let txt = _this.add.text(tile.pixelX + 30, tile.pixelY + 40, '(' + x + ',' + y + ');' + xyIndex, { fontSize: '12px', fill: '#333' }).setDepth(99999);
                        // let txt = _this.add.text(tile.pixelX + 30, tile.pixelY + 40, xyIndex, { fontSize: '14px', fill: '#333' }).setDepth(99999);
                    }
                    let distance = distancesConfig[xyIndex] || 0;

                    // console.log(distancesConfig[x + '-' + y], {x, y, distance, unlockedRing});
                    if(unlockedRing === -1 || distance < unlockedRing){
                        // let txt = _this.add.text(tile.pixelX + 30, tile.pixelY + 40, '(' + x + ',' + y + ');' + JSON.stringify([unlockedRing, distance]), { fontSize: '12px', fill: '#333' }).setDepth(99999);
                        let mask = _this.drawUnLock(tile);
                        _this.unlockMasks[distance] = _this.unlockMasks[distance] || [];
                        _this.unlockMasks[distance].push(mask);
                    }

                    // let xyIndex2 = x + '.' + y;
                    // _this.add.text(tile.pixelX + 40, tile.pixelY + 70, xyIndex, { fontSize: '24px', fill: '#000' }).setDepth(99999);          
                    
                    tile.xyIndex = xyIndex;
                    tile.owner = owner;
                    // if(isUpdate){
                        // _this.game.events.emit("tileAttackWin", {
                        //     xyIndex: xyIndex,
                        //     unionId: owner,
                        //     isUpdate: isUpdate
                        // });
                            
                        let blockInfo = newOccupieds[xyIndex] || {};
                        let isBattling = blockInfo.attackEndTime > -1 || blockInfo.protectEndTime > -1;
                        // console.log('drawBattleEffects', { blockInfo, isBattling, xyIndex, centerTiles, capitalType} );
                        if((capitalType !== 'togather' || !centerTiles[xyIndex]) && isBattling){
                            // tileData.data = hasTimeBlock;
                            _this.drawBattleEffects(tile, {
                                x_id: ids.x_id,
                                y_id: ids.y_id,
                                data: blockInfo
                            });
                        }
                    // }
                    // else{
                        //draw block 1st
                        _this.drawSingleBlock(tile, colors[owner], {x, y, xyIndex});
                    // }
                }
            }
        }
    }

    drawAllLandsPrice(map, isUpdate){
        if(!map){
            return;
        }
        let _this = this;
        let { width, height, cols, rows, xAxis, yAxis, capitals, capitalType } = this.mapConfig;
        
        _this.destroyPriceLayers();
        
        let { isLandscape, isMobile } = window.LeagueOfThronesConfig;
        let isRotate = isLandscape && isMobile;

        let landsDetail = this.landsDetail || {};

        for(var y = 0; y < yAxis; y++ ){
            for(var x = 0; x < xAxis; x++ ){
                let tile = map.getTileAt(x, y);
                let ids = getIdIndex(x, y, { cols, rows }, isRotate);
                let xyIndex = ids.x_id + '^' + ids.y_id;
                // let owner = tileData.unionId || 0;
                if(tile){
                    let distance = distancesConfig[xyIndex] || 0;

                    let priceData = landsDetail[xyIndex];
                    // console.log('landsDetail', landsDetail, xyIndex, priceData);
                    if(priceData && priceData['initPrice'] && !priceData['isInit']){
                        _this.drawLandPrice(tile, priceData);
                    }
                }
            }
        }
    }

    drawLandPrice(tile, priceData){
        let { mountains, degNumber, tileHeight, graphicsSkew } = this.mapConfig;
        let { initPrice = 0, currentPrice = 0, shares = 0, owner = '', xyIndex } = priceData;
        initPrice = showPriceData(initPrice, 18, 4);
        currentPrice = showPriceData(currentPrice, 18, 4);
        let price = currentPrice || initPrice;
        let change = currentPrice > 0 ? (currentPrice - initPrice)/initPrice : 0;

        let posIds = xyIndex.split('^');

        let startX = tile.pixelX + 70;
        let startY = tile.pixelY + 100;
        let changePct = '&nbsp;';
        if(owner){
            changePct = Math.round(change*100) + '%';
        }

        let color = change === 0 ? '#333' : (change > 0 ? 'green' : 'RGBA(227, 29, 12, 1)');
        let htmlString = '<div class="App-da-block-shadow">'
            + '<div style="color:' + color + '">' + changePct + '</div>'
            + '<span class="App-da-block-price">' + price + '</span>' 
            + '<span class="App-da-block-share">' + shares + '</span>' 
            + '</div>' ;
        let priceLayer = this.add.dom(startX, startY).createFromHTML(htmlString).setDepth(211).setAngle(degNumber); 
        this.priceLayers.push(priceLayer);       
        this.tilePop(priceLayer, { x: posIds[0], y: posIds[1] });
    }

    drawUnLock(tile){
        let { mountains, degNumber, tileHeight, graphicsSkew } = this.mapConfig;
        var shape = new Phaser.Geom.Polygon([
            32, 8,
            96, 8,
            128, 64,
            96, 120,
            32, 120,
            0, 64
        ]);

        var graphics = this.add.graphics({
            x: tile.pixelX + graphicsSkew*1.85,
            y: tile.pixelY - graphicsSkew
        }).setDepth(101).setAngle(degNumber/3);

        let _alpha = 0.4;
        let _tileColor = '#000000';
        graphics.fillStyle(_tileColor, _alpha);
        graphics.beginPath();

        graphics.moveTo(shape.points[0].x, shape.points[0].y);

        for (var i = 1; i < shape.points.length; i++) {
            graphics.lineTo(shape.points[i].x, shape.points[i].y);
        }

        graphics.fillPath();
        graphics.closePath();
        graphics.strokePath();

        this.unlockLayers.push(graphics);

        return graphics;
    }

    drawCods(map, isUpdate){
        if(!map){
            return;
        }
        let _this = this;
        let { width, height, cols, rows, xAxis, yAxis } = this.mapConfig;
        let cods = _this.cods;
        _this.destroyCodEffectsLayers();

        let { isLandscape, isMobile } = window.LeagueOfThronesConfig;
        const isRotate = isLandscape && isMobile;

        for(var y = 0; y < yAxis; y++ ){
            for(var x = 0; x < xAxis; x++ ){
                let ids = getIdIndex(x, y, { cols, rows }, isRotate);
                let codId = 'block_' + ids.x_id + '_' + ids.y_id;
                let codItem = cods[codId];
                if(codItem){
                    let tile = map.getTileAt(x, y);
                    if(tile){
                        _this.drawCodEffects(tile, {
                            x_id: ids.x_id,
                            y_id: ids.y_id,
                            codItem: codItem
                        });
                    }
                }
            }
        }
    }

    drawCodEffects(tile, data){
        let { width, height, tileWidth, tileHeight, cols, rows, degNumber } = this.mapConfig;
        let { pixelX, pixelY } = tile;
        let { x_id, y_id, codItem } = data;
        // console.log('drawCodEffects', data, tile)
        let img = this.add.image(pixelX + tileWidth/3, pixelY + tileHeight/2 - 10, 'codImg').setScale(0.6).setDepth(210).setAngle(degNumber);
        this.codEffectsLayers.push(img);
    }

    destroyCodEffectsLayers(){
        let layers = this.codEffectsLayers;
        layers.forEach(function(img){
            img.destroy();
        });
        this.codEffectsLayers = [];
    }

    destroyPriceLayers(){
        let layers = this.priceLayers;
        layers.forEach(function(img){
            // console.log('priceLayers 1', img);
            removeFromDOM(img.node);
            // img = null;
            img.removeElement();
            // img.destroy();
            // console.log('priceLayers 2', img);
        });
        // console.log('priceLaysers', this.priceLayers);
        this.priceLayers = [];

        function removeFromDOM(element){
            if (element.parentNode){
                element.parentNode.removeChild(element);
            }
        };
    }

    destroyUnlockLayers(){
        let layers = this.unlockLayers;
        layers.forEach(function(img){
            img.destroy();
        });
        this.unlockLayers = [];
    }

    destroyEffectsLayersByIds(imgObj){
        let effectsLayers1 = this.effectsLayers1 || {};
        for(var id in effectsLayers1){
            let img = effectsLayers1[id];
            if(img){
                img.destroy();
                delete this.effectsLayers1[id];   
            }
        }

        let effectsLayers2 = this.effectsLayers2 || {};
        for(var id in imgObj){
            let img = effectsLayers2[id];
            if(img){
                img.destroy();
                delete this.effectsLayers2[id];   
            }
        }
        this.removeOccupieds = {};
    }

    drawCenterEffects(centerTiles){
        let occupieds = this.occupieds || {};
        let { width, height, tileWidth, tileHeight, cols, rows, degNumber } = this.mapConfig;

        let isBattle = false;
        let isProtect = false;
        for(var xyIndex in centerTiles){
            let data = occupieds[xyIndex] || {};
            if(getLastTime(data.attackEndTime) > 0){
                isBattle = true;
            }
            if(data.protectEndTime > 0){
                isProtect = true;
            }
        }

        let x = (width - 134)/2;
        let y = (height)/2 - 50; //tileWidth
        if(isProtect){
            let img = this.add.image(x + 15, y + 30, 'protectImg').setDepth(210).setAngle(degNumber);
            for(var xyIndex in centerTiles){
                this.effectsLayers1[xyIndex] = img;
            }
            return;
        }    
        if(isBattle){
            let img = this.add.image(x + 10, y + tileHeight + 10, 'battleImg').setScale(0.6).setDepth(210).setAngle(degNumber);
            for(var xyIndex in centerTiles){
                this.effectsLayers1[xyIndex] = img;
            }
        }
    }

    drawBattleEffects(tile, tileData){
        let { width, height, tileWidth, tileHeight, cols, rows, degNumber, graphicsSkew } = this.mapConfig;

        let { pixelX, pixelY } = tile;
        let { x_id, y_id, data } = tileData;
        let xyIndex = x_id + '^' + y_id;;

        if(data.protectEndTime > 0 && !this.effectsLayers2[xyIndex]){
            let img = this.add.image(pixelX + tileWidth/3.2, pixelY + tileWidth/3.2, 'protectImg').setScale(0.4).setDepth(210).setAngle(degNumber);
            this.effectsLayers2[xyIndex] = img;
            return;
        }    
        if(getLastTime(data.attackEndTime) > 0 && !this.effectsLayers2[xyIndex]){
            let img = this.add.image(pixelX + tileWidth/3.2, pixelY + tileWidth/3.2, 'battleImg').setScale(0.3).setDepth(210).setAngle(degNumber);
            this.effectsLayers2[xyIndex] = img;
        }
    }

    //  Draw the polygon
    drawSingleBlock(tile, tileColor, posData){
        let _this = this;
        let { mountains, degNumber, tileHeight, graphicsSkew } = this.mapConfig;

        var shape = new Phaser.Geom.Polygon([
            32, 8,
            96, 8,
            128, 64,
            96, 120,
            32, 120,
            0, 64
        ]);

        let _alpha = 0;
        if(tile.owner){
            _alpha = 0.8; 
        }
        var graphics = addGraphics(tileColor, _alpha)
        graphics.setInteractive(shape, Phaser.Geom.Polygon.Contains);

        _this.game.events.on("gameover", function(data) {
            graphics.setInteractive(shape, Phaser.Geom.Polygon.Contains);
        });
        _this.game.events.on("gameout", function(data) {
            graphics.disableInteractive(shape, Phaser.Geom.Polygon.Contains);
        });

        
        graphics.on('pointerup', function (gameObject) {
            if(isDragging){
                return;
            }

            let {x, y, xyIndex} = posData;
            // console.log('pointerup-pos', tile, x + '.' + y + '===' + xyIndex);
            // console.log('pointerup-pos', tile, gameObject);

            let unlockedRing = _this.unlockedRing;
            let distance = distancesConfig[xyIndex] || 0;

            if(unlockedRing > distance){
                return;
            }

            _this.game.events.emit("TileClick", {
                pop: true,
                data: {
                    x: x,
                    y: y,
                    isBlock: !!mountains[xyIndex] || false,
                    xyIndex: xyIndex,
                    target: tile
                }
            });
        });

        _this.game.events.on("tileAttackWin", function(data) {
            let unionId = data.unionId;
            if(data.xyIndex === tile.xyIndex && unionId !== 0){
                let _tileColor = colors[unionId];
                // addGraphics(_tileColor, 0.8);
                graphics.setActive(true);
                graphics.fillStyle(_tileColor, 0.8);
                graphics.fillPath();

                // if(data.xyIndex == '-7^5'){
                //     console.log('tileAttackWin receive', data, _tileColor);                    
                // }

                //isUpdate === update by occupydata change donot need notify tile-modal
                //!isUpdate === from tile attack
                if(!data.isUpdate){
                    window.gamePVP.events.emit("tileUpdated");                    
                }
            }
        });

        function addGraphics(_tileColor, _alpha){
            var graphics = _this.add.graphics({
                x: tile.pixelX + graphicsSkew*1.85,
                y: tile.pixelY - graphicsSkew
            }).setDepth(101).setAngle(degNumber/3);

            graphics.fillStyle(_tileColor, _alpha);
            graphics.beginPath();

            graphics.moveTo(shape.points[0].x, shape.points[0].y);

            for (var i = 1; i < shape.points.length; i++) {
                graphics.lineTo(shape.points[i].x, shape.points[i].y);
            }

            graphics.fillPath();
            graphics.closePath();
            graphics.strokePath();
            return graphics;
        }
    }

    setMove(_this){
        let { width, degNumber, topLeft } = _this.mapConfig;
        let isFirstMove = _this.isFirstMove;
        if(!isFirstMove && degNumber > 0){
            const cam = _this.cameras.main;
            cam.scrollX =  width + 200;
            cam.scrollY = -50; 
            _this.isFirstMove = true;  
        }

        _this.input.once('pointerdown', function (pointer) {
            logTrace(pointer, 'map.scene setMove')
            _this.sceceMove(pointer);
        }, _this);
        _this.game.events.on("mapMove", function(data){
            // console.log(data, 'mapMove');
            const cam = _this.cameras.main;
            cam.scrollX =  data.scrollX;
            cam.scrollY =  data.scrollY;
        });
    }

    sceceMove(pointer){
        let _this = this;

        var hand = this.add.image(pointer.worldX, pointer.worldY, 'wartC');
        logTrace(pointer, 'map.scene move')
        hand.drag = this.plugins.get('rexdragplugin').add(hand);

        const cam = this.cameras.main;
        window.LotMapCam = cam;
        logTrace(cam, 'map.scene cam')

        let x1 = 0, y1 = 0;
        hand.on('dragstart', function(pointer, dragX, dragY){
            x1 = pointer.worldX;
            y1 = pointer.worldY;
            logTrace({pointer, x1, y1 }, 'map.scene dragstart')
        }, hand);
        hand.on('drag', function(pointer, dragX, dragY){
            isDragging = true;
            _this.game.events.emit("drag", true);
            // logTrace({pointer, dragX, x1, dragY,y1 }, 'map.scene drag')

            // if(window.LeagueOfThronesConfig.isMobile){
            //     cam.scrollY -=  (x1 - pointer.worldX)/5;
            //     cam.scrollX -=  (y1 - pointer.worldY)/5;
            // }else{
                cam.scrollX +=  x1 - pointer.worldX;
                cam.scrollY +=  y1 - pointer.worldY;
            // }
        }, hand);

        hand.drag.drag();

        let setMove = _this.setMove;
        hand.on('dragend', function(){
            hand.destroy();
            setTimeout(function(){
                isDragging = false;
                _this.game.events.emit("drag", false);
            }, 0);
            setMove(_this);
        }, hand);
    }

    update() {
        const cam = this.cameras.main;
        if (this.cursors.left.isDown){
            cam.scrollX -= 20;
        }else if (this.cursors.right.isDown){
            cam.scrollX += 20;
        }

        if (this.cursors.up.isDown){
            cam.scrollY -= 20;
        }else if (this.cursors.down.isDown){
            cam.scrollY += 20;
        }
    }
}