import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import NumberFormat from 'react-number-format';


import SeasonStatus from './status';
import MyEvents from './my-events';

import { CreateIcon, ToastTip } from '../../components'

import { unionImagePath, generalImagePath, getNickName} from '../../utils/ui-res'
import * as cache from '../../utils/cache'
import { numberFormat } from '../../utils/utils'
import { logTrace } from '../../utils/log'

import Texts2 from '../../const/texts2.json';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function CurrentUser(props){
    const dispatch = useDispatch(); 

    const { gameStart } = useSelector(function(state){
        return state.dauction;
    });
    const { seasonInfo, unionId, address } = gameStart;

    let currentUser = {
        avatar: -1,
        username: address,
        unionId
    };

    const [myEventsPop, setMyEventsPop] = useState(false);
    
    return(<>
        <div className="App-userInfo" onClick={ ()=>{ setMyEventsPop(true) } }>
            <div className="App-general-item">
                <span className="App-general-item-avatar" _ga_event="auction.user.avatar">
                    <img src={ generalImagePath({generalId: currentUser.avatar}) } />
                </span>
                <img className="union" src={ unionImagePath(currentUser.unionId) } title={ currentUser.username }/>
            </div>

            <SeasonStatus />
        </div>
        
        {myEventsPop && <MyEvents address={ address } seasonId={ seasonInfo.sid } close={ (type)=>{ setMyEventsPop(false); } } />}
    </>);
}