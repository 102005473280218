import React, {useEffect,useState} from 'react';
import Phaser from 'phaser';
import { useSelector, useDispatch } from 'react-redux';
import NumericFormat from 'react-number-format';

import * as DASlices from '../../da/slice';
import * as EthFuns from '../../wallets/ethereum';
import * as DAContract from '../../da/contract';

import { colors, unionImagePath, getNickName } from '../../utils/ui-res'
import { GoBack, CreateIcon } from '../../components'

import { Texts, Texts2, DATexts } from '../../const/';
import { zetaTransactionAddressLink } from '../../const';

import { numberFormat, showPriceData } from '../../utils/utils';
import { getActiveRewardPool } from '../activity/activity';

import Modal from 'react-modal';
Modal.setAppElement('#root');

export default function SeasonRank(props){
    const dispatch = useDispatch();
    const { seasonId } = props;

    let types = [ 'Income', 'Conquest Reward', 'Individual Reward', 'Active Reward' ];
    const [currentType, setCurrentType] = useState(types[0]);
    const [individualReward, setIndividualReward] = useState([]);

    let { gameInfo, rewardPoolConfig, seasonReword = {}, seasonIncomes, activeRewards } = useSelector(function(state){
        return state.dauction;
    });    
    let activeRewardPool = getActiveRewardPool(gameInfo, rewardPoolConfig, seasonId);

    const rewords = seasonReword[seasonId] || [];
    const incomes = seasonIncomes[seasonId] || [];
    const _activeRewards = activeRewards[seasonId] || {};
    const  { totalPoint = 1 } = _activeRewards;
    const activeRewardsCurrent = _activeRewards.data || [];

    // console.log('all', seasonId, seasonIncomes, seasonReword)
    // console.log(seasonReword, rewords, seasonId)

    function getFinalReward(rewords){
        let item = {};
        for(let reword of rewords){
            if(reword.finalReward){
                item = reword;
                break;
            }
        }
        return [item];
    }


    useEffect(()=>{
        let list = getFinalReward(rewords);
        setIndividualReward(list);
    }, [currentType]);

    useEffect(()=>{
        dispatch(DASlices.getSeasonReword({ seasonId }))
        dispatch(DASlices.getDARewords({ seasonId }))
        dispatch(DASlices.getActiveRewards({ seasonId, pageNumber: 0, pageSize: 200 }))
    }, []);

    return (<Modal
        style = {{
            overlay: { backgroundColor: 'rgba(0,0,0,0.2)' },
            content: {
                height: '500px',
                marginTop: '-250px'
            }
        }}
        className = { 'App-game-modal-sign-overview App-game-modal-gray' }
        isOpen={ true }
        onRequestClose = { () =>{ props.close() } }
        contentLabel="Camp Overview">
        <div style={{ position: 'relative' }}>
            <div _ga_event={ "auction.signup.stat.close" } onClick={ ()=>{ props.close() } }>
                <GoBack size="60px" />
            </div>
            <div className="App-game-modal-header" style={{ lineHeight: '3rem' }}>Rank</div>
            <div className="App-general-logis" style={{ margin: '20px 0 0',  width: '100%', textAlign: 'center' }}>
                <div className="App-general-types" style={{ width: '100%', textAlign: 'center' }}>
                {types.map((item, index)=>
                    <div key={ item } 
                        className={ 'App-general-tab-normal ' + (currentType === item ? 'App-general-tab-hightlight' : '') } 
                        _ga_event={ "auction.signup.stat.type" }
                        onClick={ (e)=> {setCurrentType(item);} }>
                        <span>{ item }</span>
                    </div>
                )}
                </div>
            </div>

            {currentType === 'Income' && <div className="App-game-modal-content1" style={{ padding: '0', height: '370px', overflow: 'auto' }}>
                <div className="App-game-table App-game-table-rank"><table>
                    <thead><tr>
                        <td className="center rank">{ Texts['dialog.rank'] }</td>
                        <td className="player">{ Texts['dialog.player'] }</td>
                        <td className="center">{ 'Camp' }</td>
                        <td className="right">{ 'Stock Count' }</td>
                        <td className="right">{ DATexts['dialog.reward.reward'] } &nbsp;&nbsp;</td>
                    </tr></thead>
                    <tbody>

                    {incomes.map((item, index) => (
                        <tr key={ 'rank' + index }>
                            <td className="center"><span className="index">{ index + 1 }</span></td>
                            <td><a href={ zetaTransactionAddressLink + item.owner } target="_blank" className="App-reward-zeta-address" title={ item.owner }>
                                { item.owner }
                            </a></td>
                            <td className="center">
                                <img alt="" style={{ width: '20px',borderRadius: '10px', marginTop: '4px' }} src={ unionImagePath(item.unionId) } />
                            </td>
                            <td className="right">
                                <CreateIcon type="camp" />  &nbsp;
                                { item.shares }
                            </td>
                            <td className="right" hash="hash"> 
                                <CreateIcon type="zeta" /> &nbsp;
                                { showPriceData(item.divident/1 || 0, 18, 4) } &nbsp;&nbsp;
                            </td>
                        </tr>)
                    )}
                    </tbody>
                </table></div>
            </div>}

            {currentType === 'Conquest Reward' && <div className="App-game-modal-content1" style={{ padding: '0', height: '370px', overflow: 'auto' }}>
                <div className="App-game-table App-game-table-rank"><table>
                    <thead><tr>
                        <td className="center rank">{ Texts['dialog.rank'] }</td>
                        <td className="player">{ Texts['dialog.player'] }</td>
                        <td className="center">{ 'Camp' }</td>
                        <td className="right">{ 'Stock Count' }</td>
                        <td className="right">{ DATexts['dialog.reward.reward'] } &nbsp;&nbsp;</td>
                    </tr></thead>
                    <tbody>
                    {rewords.map((item, index) => (
                        <tr key={ 'rank' + index }>
                            <td className="center"><span className="index">{ index + 1 }</span></td>
                            <td><a href={ zetaTransactionAddressLink + item.address || item.username } target="_blank" className="App-reward-zeta-address" title={ item.address || item.username }>
                                { item.address || item.username }
                            </a></td>
                            <td className="center">
                                <img alt="" style={{ width: '20px',borderRadius: '10px', marginTop: '4px' }} src={ unionImagePath(item.unionId) } />
                            </td>
                            <td className="right">
                                <CreateIcon type="camp" />  &nbsp;
                                { item.shares }
                            </td>
                            <td className="right"> 
                                <CreateIcon type="zeta" /> &nbsp;
                                { showPriceData(item.unionReward/1 || 0, 18, 4) } &nbsp;&nbsp;
                            </td>
                        </tr>)
                    )}
                    </tbody>
                </table></div>
            </div>}

            {currentType === 'Individual Reward' && <div className="App-game-modal-content1" style={{ padding: '0', height: '370px', overflow: 'auto' }}>
                <div className="App-game-table App-game-table-rank"><table>
                    <thead><tr>
                        <td className="center rank">{ Texts['dialog.rank'] }</td>
                        <td className="player">{ Texts['dialog.player'] }</td>
                        <td className="center">{ 'Camp' }</td>
                        <td className="right">{ 'Stock Count' }</td>
                        <td className="right">{ DATexts['dialog.reward.reward'] } &nbsp;&nbsp;</td>
                    </tr></thead>
                    <tbody>
                    {individualReward.map((item, index) => (
                        <tr key={ 'rank' + index }>
                            <td className="center"><span className="index">{ index + 1 }</span></td>
                            <td><a href={ zetaTransactionAddressLink + item.address || item.username } target="_blank" className="App-reward-zeta-address" title={ item.address || item.username }>
                                { item.address || item.username }
                            </a></td>
                            <td className="center">
                                <img alt="" style={{ width: '20px',borderRadius: '10px', marginTop: '4px' }} src={ unionImagePath(item.unionId) } />
                            </td>
                            <td className="right">
                                <CreateIcon type="camp" />  &nbsp;
                                { item.shares }
                            </td>
                            <td className="right"> 
                                <CreateIcon type="zeta" /> &nbsp;
                                { showPriceData(item.finalReward/1 || 0, 18, 4) } &nbsp;&nbsp;
                            </td>
                        </tr>)
                    )}
                    </tbody>
                </table></div>
            </div>}

            {currentType === 'Active Reward' && <div className="App-game-modal-content1" style={{ padding: '0', height: '370px', overflow: 'auto' }}>
                <div className="App-game-table App-game-table-rank"><table>
                    <thead><tr>
                        <td className="center rank">{ Texts['dialog.rank'] }</td>
                        <td className="player">{ Texts['dialog.player'] }</td>
                        <td className="right">{ 'Point' }</td>
                        <td className="right">{ DATexts['dialog.reward.reward'] } &nbsp;&nbsp;</td>
                    </tr></thead>
                    <tbody>
                    {activeRewardsCurrent.map((item, index) => (
                        <tr key={ 'rank' + index }>
                            <td className="center"><span className="index">{ index + 1 }</span></td>
                            <td><a href={ zetaTransactionAddressLink + item.address || item.username } target="_blank" className="App-reward-zeta-address" title={ item.address || item.username }>
                                { item.address || item.username }
                            </a></td>
                            <td className="right">
                                <CreateIcon type="score" size="20" /> &nbsp;
                                { item.point }
                            </td>
                            <td className="right"> 
                                <CreateIcon type="zeta" /> &nbsp;
                                <NumericFormat value={ showPriceData(activeRewardPool * item.point/totalPoint, 18, 4) } displayType="text" thousandSeparator="," /> &nbsp;&nbsp;
                            </td>
                        </tr>)
                    )}
                    </tbody>
                </table></div>
            </div>}
        </div>
    </Modal>);
}